export default {
  tabbar: {
    首页: 'Дом',
    市场: 'рынок',
    任务: 'Задача',
    团队: 'команда',
    我的: 'мой'
  },
  navbar: {
    充值: 'перезарядка',
    提现: 'снять деньги',
    帮助: 'Помогите',
    分享: 'приглашать',
	推广: 'доход от рекламы',
    活动: 'бонус',
    游戏: 'игра',
  },	 
  payment: {
    付款方式详情: 'Сведения о способе оплаты',
    银行卡: 'банковская карта',
    钱包地址: 'адрес кошелька',
    交易密码: 'пароль транзакции',
    保存: 'Сохранить'
  },
  market: {
    市场: 'рынок',
    名称: 'имя',
    价格: 'цена',
  },
 
  task: {
    任务: '任务',
	我的余额: '我的余额',
    增加订单数量: '增加订单数量',
    今日剩余订单: '今日剩余订单',
    频率: '频率',
    手动交易: '手动交易',
    预期收益: '预期收益',
    需要启动存款: '需要启动存款',
    任务说明: '任务说明',
    每天可以完成10个任务: '每天可以完成10个任务',
    联系VIP助手增加任务数量: '联系VIP助手增加任务数量',
    每个用户的任务总数为300次: '每个用户的任务总数为300次',
    购买机器人无需人工操作即可自动交易: '购买机器人无需人工操作即可自动交易',
    我知道了: '我知道了'
  },
  邀请好友注册领取佣金:"Приглашайте друзей зарегистрироваться и получайте комиссионные",
  领取:"Получать",
  未满足条件:"Условие не выполнено",
  总收入:"Общая прибыль",
  投资建议:"※ Реализуйте инвестиционный план со сложными процентами, чтобы получить более существенную прибыль.",
  语言切换: 'переключатель языка',
  POS区块节点挖矿: 'Количественная торговля с помощью ИИ',
  节点类型: 'Робот\nТип',
  加入价格: 'Купить\nЦена',
  质押资金: 'POS\nфонды',
  质押周期: 'Период\n(дни)',
  每日收入: 'Дневной\nдоход',
  累计积分: 'Накоплено\nОчки',
  代理等级: 'Агент\nУровень',
  下级折扣比: 'подчиненные\nСкидка',
  代理折扣比: 'Агент\nСкидка',
  下属等级: 'Подчиненный\nУровень',
  代理佣金比率: 'Агент\nКомиссия\nСтавка',
  下属每日收入: 'подчиненный\nежедневный\nдоход',
  代理佣金: 'Агент\nКомиссия',
  引用对象: 'Ссылка\n объект',
  积分等级折扣规则: 'Правила скидок за баллы',
  积分等级折扣规则介绍: 'Пользователи, которые успешно участвуют в количественной торговле AI, получат соответствующие баллы счета, а их вышестоящие пользователи-агенты также могут увеличить соответствующие баллы (применимо к 15% прямых подчиненных).Когда общее количество баллов счета соответствует соответствующим условиям, уровень агента учетная запись будет улучшена. Учетные записи агентов на разных уровнях могут получать разные пропорции ценовых скидок. Подробная информация о соответствующих скидках по баллам следующая:',
  积分等级折扣规则介绍2: 'Пользователи с разными отношениями (прямые) будут получать скидки и скидки на уровне агента в зависимости от участия подчиненных в количественных транзакциях Ai. Другие топ-менеджеры также получат скидки, пропорциональные своим агентам. Скидки на цену за участие в количественных транзакциях Ai: пользователи с соответствующими уровнями агента получат соответствующие привилегированные права на уровне агента при участии в количественных транзакциях Ai.',
  团队: 'команда',
  代理佣金制度: 'Система комиссионных агентств',
  代理佣金说明详情: 'Доля агентов LV1, полученных с помощью A: 8%, доля агентов LV2, полученных с помощью A: 3%, доля агентов LV3, полученных с помощью A: 2%. Пример: Ежедневная прибыль B, C и D составляет: 100 долларов США, а агентское вознаграждение LV1, полученное A: 8% * 100 = 8 долларов США. Вознаграждение агента LV2, полученное A: 3%*100=3 USD. Вознаграждение агента LV3, полученное A: 2%*100=2 USD. Общий комиссионный доход составляет: 8+3+2=13 долларов США.',
  代理结构图示: 'Схема структуры прокси',
  代理结构图示说明: 'A представляет вас, A приглашает B (подчиненный A — это B), B приглашает C (подчиненный B — это C), а C приглашает D (подчиненный C — это D). A может иметь несколько подчиненных (B1, B2, B3, B4, B5, B6 и т. д.), B (B1, B2, B3) также может иметь несколько подчиненных (C1, c2, c3, c4, c5, c6 и т. д.). ) CD...',
  高级代理佣金说明: 'Инструкции по комиссии старшего агента',
  高级代理佣金说明详情: 'A представляет вас, A приглашает B (подчиненный A — это B), B приглашает C (подчиненный B — это C), а C приглашает D (подчиненный C — это D). Вышестоящий агент будет получать различные комиссионные доходы агентства (%) в соответствии с соотношением общей суммы майнинга узла, задействованного на его собственной учетной записи и подчиненных учетных записях. A сравнивает ежедневный доход подчиненных членов, чтобы получить соответствующее соотношение вознаграждений агентства. Участвующие узлы должны быть запущены и работать. Доход агентства предоставляется INGSAI MINING для покрытия всех финансовых расходов и не влияет на ежедневный доход обычных подчиненных членов.',
  高级代理佣金说明详情2: 'Пример: общий объем майнинга старших агентов (A)>30% от общего объема майнинга младших членов (B/C/D) (Пример: A>30% B/C/D)',
  积分: 'точки',
  规则: 'правило',
  会员级别: 'уровень членства',
  经验: 'Всего очков',
  代理信息: 'информация о членах',
  更多: 'Более',
  团队奖励: 'комиссия команды',
  昨天: 'вчера',
  本星期: 'эта неделя',
  全部: 'все',
  机器人说明: 'Пользователи Plus500Ai могут приобрести несколько пакетов количественной торговли одновременно, а робот №1 может совершать покупки только 10 раз на одну учетную запись. Помните: не забудьте нажать на активацию стратегии после покупки количественного робота, иначе дохода не будет. Срок действия количественного робота рассчитывается на основе времени покупки. Пожалуйста, активируйте количественную торговую стратегию вовремя. Если количественный робот не был активирован по истечении срока его действия, вы потеряете стоимость приобретения пакета количественной торговой стратегии! !!Все пользователи, участвующие в количественной торговле Plus500Ai, пожалуйста, внимательно прочтите данное правило! !',
  会员体验: 'Опыт участника',
  机器人采购: 'Покупка робота',
  机器人启动押金: 'Сумма POS узла',
  机器人礼物: 'узел подарок',
  机器人订单: 'Порядок узлов',
  一次性总和: 'шоу',
  总计: 'Всего',
  
  被激活: 'Уже POS',
  工作中: 'АКТИРОВАНО',
  暂停: 'ПаузаPOS',
  结束: 'финиш',
  刷新: 'освежить',
  来源: 'источник',
  价格: 'Цена пакета робота',
  启动押金: 'POSколичество',
  剩余时间: 'оставшееся время',
  激活时间: 'Время активации',
  号: 'Число',
  我的资产: 'мои активы',
  退出: 'выход',
  邀请码: 'Код приглашения',
  我的钱包余额: 'баланс моего кошелька',
  我的机器人仓库: 'Мой список роботов',
  收益面板: 'панель заработка',
  去提款: 'снять деньги',
  昨天代理返利: 'Комиссия агентства вчера',
  累计代理返利: 'Суммарная комиссия агентства',
  今天机器人收益: 'Торговая прибыль на сегодня',
  累计机器人收益: 'Совокупный торговый доход',
  累计机器人回扣: 'Накопленная торговая комиссия',
  累计总回报: 'Совокупный общий доход',
  自动订单机器人数量: 'Активированный количественный торговый робот',
  我的付款方式: 'мой способ оплаты',
  个人收入记录: 'личный доход',
  团队收入记录: 'доход команды',
  记录详情:'Подробности записи',
  充值记录: 'Депозитные записи',
  我的团队成员: 'члены моей команды',
  活动中心: 'Правила Введение',
  修改登录密码: 'Настройка пароля входа',
  交易密码管理: 'Установка пароля транзакции',
  机器人: 'Торговая стратегия ИИ',
  机器人性能: 'Панель роботов',
  机器人价格: 'Торговая стратегия Цена',
  有效期: 'Срок действия сделки',
  钱包地址: 'адрес кошелька',
  天: 'день',
  去购买: 'Покупка',
  买个机器人: 'Купить квантового торгового робота',
  我的钱包余额: 'баланс моего счета',
  预计日收益: 'Доход за день',
  启动保证金: 'Стартовый депозит',
  请输入购买数量: 'Пожалуйста, введите объем покупки',
  个机器人: 'Квантово-торговый робот',
  机器人购买后需要激活才能工作: 'После покупки узла требуется соответствующее количество POS, чтобы начать пользоваться преимуществами.',
  机器人激活需要从余额中扣除相应的启动押金:
    'Для майнинга Node POS необходимо вычесть соответствующую сумму POS из баланса счета.',
  机器人暂停机器人停止时将返还启动押金不活跃的机器人可以赠送:
    'Количество POS, которое может быть возвращено сразу после приостановки майнинга POS на узле.',
  购买: 'Купить',
  点击去了解我们: 'Количественная торговля',
  购买机器人: 'Купить робота',
  快速入口: '快速入口',
  '日/个人受益': 'Дневной / личный доход',
  购买教程: 'Список торговых роботов AI',
  激活金额: 'POS-стоимость',
  订单数量: 'количество заказа',
  我们的合作伙伴: 'Наши партнеры по крипторынку',
  提款: 'снять деньги',
  提款金额: 'сумма вывода',
  你还没有添加银行卡: 'Вы не добавили банковскую карту',
  提现说明: 'Инструкции по снятию средств',
  说明1: '1.Вы можете выбрать одним кликом или вручную ввести сумму пополнения',
  说明2: '2.Рекомендуется использовать USDT (TRC20), комиссия за перевод самая низкая.',
  说明3: '3.Часы обслуживания клиентов: с понедельника по пятницу с 8 утра до 6 вечера по центральноамериканскому времени.',
  说明4: 'Пользователям, которые используют USDT для пополнения, необходимо загрузить правильный скриншот успешного перевода и TXID, и учетная запись будет успешно получена после того, как платформа пройдет проверку.',
  说明5: '4.Минимальная сумма пополнения составляет 8 USDT, что удобно системе для быстрого просмотра и обработки счета.',
  说明6: '5.Если у вас есть какие-либо вопросы о подзарядке, пожалуйста, свяжитесь с онлайн-службой поддержки клиентов вовремя для получения помощи.',
  确认提款: 'Подтвердить вывод ',
  请输入手机号码:'номер телефона',
  请输入提现金额: 'Сумма вывода ',
  提现费:'Плата за снятие средств',
  提现须知: 'Пожалуйста, внимательно прочитайте правила вывода',
  TIPS1: '1:Ежедневное время проверки вывода средств — с понедельника по пятницу с 8 до 6 часов. Снятие средств может быть произведено в выходные дни, а время рассмотрения вывода - понедельник.',
  TIPS2: '2:7X24-часовая система, снятие денег в любое время, поступление на счет не позднее 24 часов и поступление на счет в течение 5 минут в кратчайшие сроки',
  TIPS3: '3:Время онлайн-обслуживания клиентов: с понедельника по пятницу с 8 до 6 часов.',
  TIPS4: '4:Из-за стоимости перевода USDT платформа взимает комиссию за снятие средств, когда пользователь снимает деньги.',
  TIPS5: '5:Минимальная сумма вывода 10 USDT',
  钱包余额: 'баланс кошелька',
  输入数量:"Введите количество",
  数量:"количество",
  手机号码:"номер телефона",
  手机号码已存在:"Номер мобильного телефона зарегистрирован",
  邮箱:"Почта",
  输入手机号码: 'Введите мобильный номер',
  请输入登录密码: 'логин Пароль',
  登录: 'Авторизоваться',
  输入账号: 'Введите номер счета',
  密码: 'пароль',
  点击注册: 'У вас нет учетной записи? Создайте ее сейчас!',
  忘记密码: 'забыть пароль',
  重新登录: 'войти снова',
  密码找回成功: 'Пароль успешно восстановлен',
  找回成功请重新登录: 'Получение успешно, пожалуйста, войдите снова',
  确认: 'подтверждать',
  发送验证码: 'Отправить код подтверждения',
  再次输入密码: 'Подтвердите пароль',
  输入验证码: 'Введите CAPTCHA',
  输入手机验证码:"Введите мобильный OTP",
  输入手机号: 'Введите мобильный номер',
  输入密码: 'логин Пароль',
  快速注册: 'Быстрая регистрация',
  注册: 'регистр',
  立即登录: 'Нажмите, чтобы авторизоваться',
  请输入邮箱: 'адрес Gmail',
  输入用户名: 'Введите имя пользователя',
  请输入邀请码: 'Код приглашения',
  返回: 'возврат',
  密码找回失败: 'Не удалось восстановить пароль',
  输入的信息有误: 'Введенная информация неверна',
	获取验证码:"CAPTCHA",
	手机号码不能为空:"Укажите номер телефона.",
	账号不存在:"Аккаунт не существует",
	发送成功:"Успешно отправлено",
	今日收益:"（текущий доход）",
	"您好如果您中途终止POS-STAKING系统将收取5%的STAKING费用":"Здравствуйте, если вы отключите POS на полпути, система взимает плату за управление POS в размере 5%. Специальное напоминание: после того, как POS бесплатного опыта узла будет приостановлен, вы не сможете получить его снова, а также вы не сможете получить какие-либо сборы! !",
	我同意:"Я согласен",
	用户账号ID:"ID аккаунта",
	取消:"Отмена",
	日收益:"Доход за день",
	请输入购买数量:"Пожалуйста, введите объем покупки",
	数量不能为空:"Количество не может быть пустым",
	加载中:"Загрузка...",
	唯一码:"Кодирование роботов",
	未激活:"НЕАКТИВНЫЙ",
	激活:"Старт стратегии",
	购买时间:"время покупки",
	"钱包余额不足，激活该机器人失败":"Недостаточный баланс учетной записи, майнинг узла POS не может быть выполнен",

	运行时长:"время работы",
	签约购买:"покупка баланса",
	系统赠送:"Системный подарок",
	状态:"состояние",
	正常:"обычный",
	我的机器人:"Мой квантовый торговый робот",
	一级会员:"1-й уровень",
	二级会员:"2-й уровень",
	三级会员:"3-й уровень",
	人:"",
	充值客服:"Подзарядка обслуживания клиентов",
	充值:"перезарядка",
	提现:"отзывать",
	提款密码:"Пароль для вывода",
	设置交易密码:"Установить пароль транзакции",
	登录密码:"логин Пароль",
	请输入交易密码:"Введите пароль транзакции",
	再次请输入交易密码:"Подтвердите пароль транзакции",
	确认: 'подтверди',
	手机号码不能为空:"Укажите номер телефона.",
	两次密码不一致:"Два пароля не совпадают",
	请输入验证码:"пожалуйста, введите проверочный код",
	操作成功:"Успешная операция",
	"用户名或密码不正确,登录失败":"Имя пользователя или пароль неверны, авторизация не удалась",
	登录成功:"Вход выполнен успешно",
	充值说明:"Инструкции по пополнению баланса",
	请先设置支付密码:"Сначала установите пароль транзакции",
	复制成功:"скопировать успешно",
	冻结机器人做单本金:"Заморозить количественные фонды",
	待审核:"ожидает оценки...",
	成功:"успех",
	失败:"неудача",
	审核中:"на рассмотрении",
	在线充值:"Пополнить счет онлайн",
	描叙:"описывать",
	POS节点挖矿LV0:" Майнинг узла POS-LV0",
	POS节点挖矿LV1:" Майнинг узла POS-LV1",
	POS节点挖矿LV2:" Майнинг узла POS-LV2",
	POS节点挖矿LV3:" Майнинг узла POS-LV3",
	POS节点挖矿LV4:" Майнинг узла POS-LV4",
	POS节点挖矿LV5:" Майнинг узла POS-LV5",
	银行卡提现:"Снятие наличных с банковской карты",
	USDT提现:"Вывод USDT",
	三级代理:"Агент 3 уровня",
	一级代理:"Агент 1 уровня",
	二级代理:"Агент 2 уровня",
	一级:"Уровень 1",
	二级:"Уровень 2",
	三级:"Уровень 3",
	做单:"Добыча узлов",
	登录失败:"Ошибка входа",
	请勿重复操作:"Не повторяйте частые операции",
	邀请码不正确:"код приглашения неверный",
	团队返佣:"комиссия команды",
	购买机器人返佣:"Комиссия по роботам",
	本金返还:"POS-возврат",
	佣金收入:"Комиссионный доход",
	时间:"время",
	机器人做单返还:"POSвозврат денег",
	涨幅:"увеличивать",
	市场:"рынок",
	客服:"Служить",
	验证码错误:"Ошибка кода подтверждения",
  付款方式:"USDTАдрес вывода",
	我的留言:"Адрес вывода",
  暂无数据: 'Нет данных',
  银行卡:'банковская карта',
  修改: 'Пересмотреть',
 '请确保您的钱包地址正确，如果您的地址输入错误而导致收不到钱，请及时联系在线客服协助':'Пожалуйста, убедитесь, что адрес вашего кошелька правильный.Если ваш адрес введен неправильно и деньги не могут быть получены, пожалуйста, своевременно обратитесь в онлайн-службу поддержки за помощью.',
 连接借记卡:"连接借记卡",
 银行名称:'Банковский счет',
 姓名姓氏: 'Имя',
 IBAN号码:'IBAN 号码',
 '* 重要提示:借记卡信息必须真实有效才能取款。':'*Важное напоминание: информация о банковском счете должна быть правдивой и действительной, прежде чем вы сможете снять деньги.。',
 帮助中心:'центр помощи',
 会员须知:'Уведомление участника',
 '24小时内使用USDT实时取款（推荐）':'1. 24小时内使用USDT实时取款（推荐）',
 '银行卡 取款 *取款时间为10:00-18:00 *发起取款后2小时内入账*申请非取款时间或跨行取款将于次日10:00-18:00到账':' 2.银行卡 取款 *取款时间为10:00-18:00 *发起取款后2小时内入账*申请非取款时间或跨行取款将于次日10:00-18:00到账',
 我知道了:'Я понимаю',
 链接点击:'Ссылка на регистрацию рефералов',
 确定:'Конечно',
 建立自己的团队邀请:'создай свою команду',
 此账号已冻结:"Этот аккаунт был заморожен",
 手机号存已存在:"Номер мобильного телефона уже существует",
 注册账号已存在:"Зарегистрированный аккаунт уже существует",
 请确定新密码:"Подтвердите новый пароль.",
 请再次输入登录密码:"подтвердите логин пароль",
 密码长度不能少于6位:"Длина пароля не может быть меньше 6 символов",
 加入:'Присоединяйся',
 "你出售的机器人对方已接受,出售所得已打款到您账户,请查收。":
 	    "你出售的节点对方已接受,出售所得已打款到您账户,请查收。",
 	"你出售的机器人对方已拒绝接受":"你出售的节点对方已拒绝接受",
 	向你赠送一台机器人:"向你赠送一个节点，待确认",
 	向你出售一台机器人:"向你出售一个节点，待确认",
 	出售价格不能超过机器人价格:"出售价格不能超过节点价格",
 	银行卡已被绑定:"Банковский счет был использован",
 	USDT_TRC20提现:'Вывод USDT_TRC20',
 	USDT_ERC20提现:'Вывод USDT_ERC20',
 	银行卡提现:'Вывод средств со счета в банке',
 	邀请赠送:'Пригласительный бонус',
 	机器人返佣:'Комиссия по роботам',
 	升级:'удачная покупка',
 	充值主网:'充值主网',
   "您被限制出售机器人,请联系客服了解详情":
     "您被限制出售节点,请联系客服了解详情",
   交易编号: "номер сделки",
   消息详情:"детали сообщения",
   个人收益累计:"накопленный личный доход",
   今日代理收益:"Доход агентства сегодня",
   代理累计收益:"Совокупный доход агентства",
   截图保存推荐给朋友:"Поделитесь своей реферальной ссылкой на регистрацию и пригласите друзей присоединиться к платформе Plus500Ai, чтобы получать комиссионные вознаграждения от командного дохода. Комиссия с дохода для членов команды третьего уровня составляет 8%-3%-2%. Комиссия команды основана на ежедневных данных о доходах участников более низкого уровня и будет автоматически рассчитываться и заноситься на баланс вашего счета после 00:00 каждый день.",
   复制:"копия",
   充值提示1:"Совет 1: Минимальное пополнение 10 USDT, пополнить можно, иначе не зачислят",
   充值提示2:"Совет 2: Пополнение должно быть подтверждено в цепочке.После успешного пополнения ожидается, что баланс кошелька поступит примерно через одну минуту.。",
   请输入正确的充值金额:"Сумма депозита ≥ 8 USDT",
   推荐:"рекомендовать",
   充值金额:"Сумма пополнения",
   请上传付款截图:"Пожалуйста, загрузите скриншот успешного платежа",
   交易号: 'Хэш-номер транзакции',
   充值ID提示:"Пожалуйста, вставьте серийный номер транзакции Txid",
   "请确保钱包地址无误，如因钱包地址填写错误导致无法收款而造成的损失，将有用户自己承担。":"Пожалуйста, убедитесь, что адрес кошелька указан правильно.Если адрес кошелька заполнен неправильно и убытки, вызванные невозможностью получения денег, несет пользователь.。",
	上传凭证:"Загрузите скриншот ваучера оплаты",
	充值确认: "Отправить",
	不能为空:"Не может быть пустым",
  绑定钱包地址:'Привязка адреса кошелька',
	备注:"Примечание",
	请输入银行卡号:"Введите номер банковского счета",
	请输入你的名字:"введите ваше имя",
	银行卡号:"Банковский счет",
	添加银行卡:"Привязать банковский счет",
	请选择银行:"Пожалуйста, выберите банк",
	请输入钱包地址:"Введите адрес кошелька",
	钱包地址:"адрес кошелька",
	"密码错误次数过多,请等待x秒后再试":"Слишком много неправильных паролей, подождите 600 секунд и повторите попытку.",
	"此账号已冻结,请联系客服":"Эта учетная запись была заморожена, пожалуйста, свяжитесь со службой поддержки",
	手机号格式错误:"Неправильный номер телефона",
	"取款须知":"Уведомление о выходе",
	"须知1":"1. Используйте USDT-TRC20 для вывода средств в режиме реального времени в течение 24 часов (рекомендуется)",
	"须知2":"2. Вывод в субботу и воскресенье",
	"须知3":"*Пользователи могут снимать деньги в субботу и воскресенье",
	"须知4":"*Вывод средств в выходные дни будет осуществляться в понедельник с 10:00 до 20:00.",
	我知道了:"Я понимаю",
	"提现0":"1USDT=1USD ERC20",
	"提现1":"提示1：最低单笔提现100USDT/天，单笔交易手续费5USDT/次",
	"提现2":"TRC20提示2：每次提款最低10USDT，每次交易手续费1USDT。",
	"提现3":"银行账户提示3：最低单次取款为$10，交易手续费为交易金额的$6%",
	"提现4":"提现时间：提现申请提交后24小时内到账",
	
	已绑定:"связанный",
	去设置:"зайти в настройки",
	注册成功:"Регистрация успех",
	汇率:"обменный курс",
	请输入正确的提现金额:"Пожалуйста, введите правильную сумму вывода",
	提款记录:"История вывода",
	"绑定的钱包地址必须跟付款钱包一致，否则不会到账。":"tip1:绑定的钱包地址必须跟付款钱包一致，否则不会到账。",
	"充值成功后预计在一分钟左右到账，请检查钱包约。":"tip2:充值成功后预计在一分钟左右到账，请检查钱包余额。",
	请先绑定钱包地址:"Сначала привяжите адрес кошелька",
	输入邮箱:"Введите почту",
	邮箱已存在:"почтовый ящик уже существует",
	注册邮箱已存在:"Зарегистрированный адрес электронной почты уже существует",
	指定用户不存在:"Указанный пользователь не существует",
	今日涨幅:"изменять",
	恭喜新用户:"поздравляю нового пользователя ",
	快捷充币:"депозит",
	快速买USDT:"Пополнение USDT",
	在线支付:"Онлайн платеж",
	自动到账:"Автоматический счет",
	线下支付:"线下支付",
	联系客服获取验证码:"Свяжитесь со службой поддержки, чтобы получить код подтверждения",
	获取:"Получить",
	退出成功:"выйти успешно",
	不能低于最小充值:"Не может быть ниже минимальной перезарядки",
	不能低于最小提现金额:"Не может быть меньше минимальной суммы вывода",
	最小提现:"Минимальный вывод",
	设置成功:"успешно настроен",
	官方充币:"системный депозит",
	银行卡充值:"Пополнение банка",
	等待审核:"ждать обзора",
	可提现金额不足:"Недостаточно суммы для вывода",
	未经授权:"несанкционированный",
	交易密码不正确:"Неверный пароль транзакции",
	提现次数不足:"Недостаточное время вывода средств",
	官网:"官网",
	账户usdt不足:"Баланс USDT на счете недостаточен",
	激活成功: "POS-успех",
	操作成功: "Успешная операция",
	修改成功:"Успешно изменено",
	注册说明:"Для проверки мобильного OTP и электронной почты достаточно выбрать только один метод проверки, и вы сможете успешно зарегистрироваться",
	弹窗内容:
	"Добро пожаловать на платформу количественной торговли Plus500Ai. Каждый пользователь может использовать регистрационный бонус в размере 1 доллара США для приобретения пакета стратегии количественной торговли роботами № 0, который действителен в течение 1 дня и бесплатно заработать 0,5 доллара США. Другие пакеты количественных торговых стратегий требуют использования соответствующих комиссий. После того, как пользователи приобретут робота, им необходимо активировать количественную торговую стратегию, прежде чем она сможет автоматически начать работу.Каждая торговая стратегия соответствует разному периоду действия. Если пользователь не нажмет кнопку активации стратегии после покупки соответствующих очков робота, вы не получите никакого торгового дохода.",
	标题1:'1. Знакомство с платформой Plus500',
	标题2:'2. Преимущества количественной торговли с использованием ИИ',
	标题3:'3. Почему стоит выбрать Plus500Ai?',
	标题4:'4. Как присоединиться к Plus500Ai',
	标题5:'5.Перспективы рынка Plus500Ai',
	标题6:'6. Модель дохода Plus500Ai',
	标题7:'7.Правила депозита и вывода средств',
	标题8:"Геймплей королевской битвы",
	内容8:"В игре Battle Royale на платформе Plu500Ai 8 комнат. Игроки могут выбрать любую комнату из 8 комнат, чтобы положить в нее золотые монеты. После окончания системного обратного отсчета в игровой системе появится убийца. Убийца случайно входит в комнату и уничтожает людей в комнате.Другие игроки могут делиться золотыми монетами в этой комнате и получать прибыль. Геймплей игры Battle Royale относительно короткий, игра длится всего несколько минут, поэтому игроки могут играть в нее в свободное время. Сумма дележа = общему количеству комнат, убитых убийцей за этот период. Фактическая сумма акций = сумма акций * 0,9 (без учета коэффициента уничтожения 0,1). Коэффициент доли = фактическая сумма доли / общая сумма ставки в других румах. Сумма выигрыша = коэффициент распределения * фактическая сумма ставки. 1 золотая монета = 1 доллар США, а для участия в игре Battle Royale требуется минимум 10 долларов США.",
	内容1:"Торговая платформа Plus500 предоставлена ​​Plus500SEY Ltd. Соответственно, Plus500SEY Ltd является эмитентом и продавцом финансовых продуктов, описанных или предлагаемых на этом сайте. Plus500SEY Ltd — компания со штаб-квартирой в Сейшельских Островах (номер компании 8426415-1) с офисами в Виктории. Компания авторизована и контролируется Управлением финансовых услуг Сейшельских островов (FSA) с номером лицензии SD039 и предоставляет контракты на разницу цен (CFD) на различные базовые продукты. Компания является быстрорастущим поставщиком CFD, который в настоящее время предлагает портфель из более чем 2800 финансовых инструментов. Plus500SEY Ltd является дочерней компанией Plus500 Ltd, акции которой котируются на основном рынке Лондонской фондовой биржи, а ее штаб-квартира находится в Хайфе.",
	内容2:'Преимущество полностью автоматизированной количественной торговли с использованием искусственного интеллекта заключается в использовании рациональных инструментов для управления транзакциями в соответствии с настройками модели.Робот не имеет эмоциональных изменений и может преодолеть человеческую природу, человеческую жадность и страх, а также многие другие недостатки и слабости в процессе работы. На ручные операции часто влияют подъемы и падения рынка и различные другие факторы, и они не могут работать в соответствии с наилучшей моделью получения прибыли в течение длительного времени.',
	内容3:'Plus500Ai постепенно укрепила свои позиции в качестве финтех-группы с несколькими активами, расширяя свои основные продукты на новых и существующих рынках, запуская новые торговые и финансовые продукты и углубляя взаимодействие с клиентами. Защитите свои активы и ключи шифрования с помощью ведущей в отрасли технологии безопасности. Plus500Ai также предлагает глобальное хранение, что означает, что мы регулируемся, проверяемся и застрахованы на сумму до 250 миллионов долларов. Размещайте свой капитал с целью не просто удерживать активы, но и заставлять их работать. Plus500Ai позволяет вам использовать свои средства различными способами: от количественной торговли с использованием искусственного интеллекта до классических первичных услуг, в зависимости от вашей толерантности к риску. Онлайн-платформа для предоставления большего количества услуг вашим пользователям без найма дополнительных инженеров по блокчейну.',
	内容4:"С момента создания Plus500Ai существовал только один официальный сайт — WWW.PLUS500AI.COM. Всем пользователям необходимо войти на официальный сайт, чтобы загрузить приложение, поскольку другие веб-сайты не принадлежат нашей компании. Для регистрации вам необходимо указать номер мобильного телефона пользователя, адрес электронной почты и код приглашения рекомендателя для быстрой регистрации. Все общение с клиентами ведется в письменной форме, по электронной почте или в онлайн-чате. Чтобы связаться с Plus500 по электронной почте, заполните форму (форму заявки) на странице «Контакты». После отправки формы заявки Plus500 отправит ответ по электронной почте непосредственно на адрес электронной почты, указанный вами в форме заявки. Если у вас есть какие-либо вопросы, пожалуйста, свяжитесь со службой поддержки клиентов.",
	内容5:'Plus500Ai работает с разными продуктами, устройствами и странами, чтобы предоставить клиентам надежные и интуитивно понятные возможности финансовой торговли.Мы всегда стремились к совершенству в наших продуктах и ​​командах. Благодаря передовым запатентованным технологиям и клиентоориентированному подходу наши пользователи получают первоклассные торговые услуги.',
	内容6:"Plus500Ai предоставляет профессиональные количественные торговые стратегии для рынка криптовалют, разработанные командой старших аналитиков данных.Пользователи, которые инвестируют в робота количественной торговли Ai, могут получать прибыль от количественной торговли, полагаясь на торговые стратегии, соответствующие роботу. Количественный торговый робот Ai циклически анализирует оптимальное время торговли на основе рыночной торговой стратегии Plus500Ai и получает количественную торговую прибыль за счет модели покупок и продаж на низком уровне по высоким ценам. Plus500Ai каждый день предоставляет инвестирующим пользователям оптимальные количественные торговые стратегии, а платформа извлекает 5% прибыли от количественного дохода пользователей в качестве операционных средств платформы.",
	内容7:'Plus500Ai в настоящее время поддерживает депозиты и снятие средств в USDT, и на официальном сайте будет объявлено, если в будущем будут открыты другие каналы. Минимальная сумма депозита и вывода составляет 10 долларов США. Самый быстрый депозит и вывод средств происходят в течение 5 минут, а самый медленный — в течение 24 часов. Ограничений на время вывода пользователя нет, вы можете вывести деньги в любое время. Вывод средств осуществляется в течение 5 минут — 24 часов в обычные рабочие дни. Вывод средств в выходные дни обрабатывается в понедельник.',
	BSC提现:"Вывод USDT_BSC",
	社区Ads收益:"Доход от рекламы сообщества",
	Ads收益区间:"Доход от рекламы 1-1000$",
	申请Ads费用:"Плата за рекламу приложения",
	Ads促销地址:"Адрес продвижения рекламы",
	填写Ads促销地址:"Заполните рекламный адрес",
	申请Ads费用须知:"Инструкции по подаче заявки на рекламные сборы",
	须知A:"1. Пользователи записывают рекламные видеоролики и загружают их на свои YouTube, Facebook, каналы или другие сообщества для продвижения рекламы, подают заявку на получение бонуса в размере от 1 до 1000 долларов США, и платформа просмотрит ваш рекламный видеоматериал через 3 дня после подачи заявки и разместит рекламу. бонус на счет в соответствии с эффектом продвижения видео Баланс вашего аккаунта, видео без каких-либо рекламных эффектов не могут быть просмотрены",
	须知B:"2. Один и тот же рекламный ролик Ads на одном и том же канале может подать заявку на получение бонуса только один раз, а новые рекламные видеоматериалы могут выходить раз в неделю.Не отправляйте злонамеренные повторные запросы. Если вы намеренно отправляете рекламные запросы, сделанные не вами на платформе несколько раз, или рекламные материалы, которые не были проверены, ваша учетная запись будет заблокирована после того, как система обнаружит",
	须知C:"3. Рекламный видеоматериал, записанный пользователем, должен иметь базовое введение в платформу и объяснение функций платформы. Продолжительность видео не менее 5 минут. Чем больше подписчиков на вашем канале, тем лучше рекламный эффект видео и тем выше Плата за рекламу, на которую вы можете подать заявку. Конкретные сборы за рекламу на примере YOUTUBE следующие:",
	须知D: "Абонент 0-2K:сумма заявки 1-20$",
	须知D1:"Абонент 3K-10K:сумма заявки 21-40$",
	须知D2:"Абонент 11К-30К: сумма заявки 41-100$",
	须知D3:"Абонент 31К-50К: сумма заявки 101-300$",
	须知D4:"Абонент 51К-100К: сумма заявки 301-1000$",
	须知D5:"4. Система будет рассматривать и обрабатывать заявки на бонусы Ads каждое воскресенье, а квалифицированные пользователи будут получать бонусы, отправленные системой.",
	请求审核:"запросить проверку",
	申请金额:"Сумма заявки",
	URL促销地址:"URL промо-адреса",
	提交时间:"время подачи",
	需要更新的银行卡不存在:"Обновляемая банковская карта не существует",
	银行卡提现:"Снятие наличных с банковской карты",
	预计收入:"Расчетный доход",
	总投资:"Всего инвестиций",
	购买价:"цена покупки",
	邀请:"Приглашать",
	成员:"члены",
	积分等级折扣规则介绍3:"Эта сумма скидки действительна только до 31 декабря 2023 года, после 1 января 2024 года будет произведена новая корректировка.",
	LV1佣金:"Комиссия УРОВНЯ 1",
	LV2佣金:"Комиссия УРОВНЯ 2",
	LV3佣金:"Комиссия УРОВНЯ 3",
	合格条件:"Депозит минимум 8$ на человека",
	随时可退:"не заперт",
	登录密码错误:"Неправильный пароль для входа",
	充值奖金:"Депозит USDT≥50USDT, бонус 3%",
	充值奖金1:"Депозит IDR≥50USDT, бонус 2%",
	充值奖金2:"Депозит THB≥50USDT, бонус 5%",
	活动已过期:"Срок действия мероприятия истек, ожидание следующего мероприятия",
	不能重复发送短信:"Вы не можете отправлять повторяющиеся текстовые сообщения в течение 10 минут. Для прямой регистрации рекомендуется использовать EMAIL CAPTCHA.",
	限购说明1:"Здравствуйте, каждый пользователь пакета торговой стратегии количественного робота № 0 может приобрести его только один раз.",
	修改钱包地址提示:"Адрес вывода USDT нельзя изменить после его привязки. Если вам нужна помощь, обратитесь в службу поддержки клиентов.",
	修改银行提示:"Банковский счет для вывода средств нельзя изменить после его привязки. Если вам нужна помощь, обратитесь в службу поддержки клиентов.",
	注册推送消息1:"Добро пожаловать в INGSAI. После того, как новые пользователи запустят узел POS-майнинга, они будут получать доход от POS-майнинга каждые 5 минут. Комиссионный доход команды будет автоматически зачислен на баланс счета примерно в 00.00 в день.",
	限购说明:"Здравствуйте, максимальное количество покупок для этого типа пакета количественного робота составляет 10. Рекомендуется приобрести другие типы пакетов количественного трейдинга.",
	注册协议:"Нажимая (Зарегистрировать аккаунт), вы подтверждаете, что вам исполнилось 18 лет. Вы знаете, что Plus500AI может использовать ваш адрес электронной почты или другие личные данные, чтобы связаться с вами в электронном виде и предоставить соответствующую информацию о своих продуктах/услугах. Мы также можем уведомлять вас о рыночных событиях, чтобы помочь вам получить максимальную отдачу от вашего торгового опыта. Вы можете управлять настройками уведомлений и изменять их на вкладках (Настройки уведомлений) и (Настройки конфиденциальности) на Платформе. Вы можете отказаться от подписки на эти сообщения в любое время. Для получения дополнительной информации ознакомьтесь с нашим (Соглашением о конфиденциальности)",
	公司说明1:"Plus500AI является зарегистрированной торговой маркой Plus500 Ltd. Plus500 Ltd осуществляет свою деятельность через следующие дочерние компании:",
	公司说明2:"Plus500SEY Ltd авторизована и регулируется Управлением финансовых услуг Сейшельских островов (лицензия № SD039).",
	公司说明3:"Plus500SEY Ltd является эмитентом и продавцом финансовых продуктов, описанных или доступных на этом веб-сайте.",
	公司说明4:"Plus500UK Ltd авторизована и регулируется Управлением финансового надзора. Регистрационный номер Управления финансового надзора: 509909. CFD на криптовалюты недоступны розничным пользователям.",
	公司说明5:"Plus500CY Ltd авторизована и регулируется Кипрской комиссией по ценным бумагам и биржам (код авторизации: 250/14). CFD на криптовалюту недоступны розничным инвесторам из Великобритании.",
	公司说明6:"Plus500AU Pty Ltd имеет следующие лицензии: AFSL (№ 417727), выданную ASIC, FSP (№ 486026), выданную FMA Новой Зеландии, и авторизованного поставщика финансовых услуг (№ 47546), выданную FSCA Южной Африки.",
	公司说明7:"Plus500EE AS ​​авторизована и регулируется Финансовой инспекцией Эстонии (лицензия № 4.1-1/18).",
	公司说明8:"Plus500SG Pte Ltd (UEN 201422211Z) имеет лицензию на оказание услуг на рынках капитала, выданную Валютным управлением Сингапура, позволяющую ей торговать продуктами рынка капитала (номер лицензии CMS100648).",
	公司说明9:"Plus500AE Ltd авторизована и регулируется Управлением финансовых услуг Дубая (F005651).",
    加入我们的社群:"Присоединяйся к нашему сообществу",
	PLUS500集团:"ГРУППА ПЛЮС500",
	受监管的操作:"РЕГУЛИРУЕМЫЕ ДЕЯТЕЛЬНОСТИ",
	健全的全球监管框架:"Надежная глобальная нормативно-правовая база",
	治理和社会责任:"УПРАВЛЕНИЕ И СОЦИАЛЬНАЯ ОТВЕТСТВЕННОСТЬ",
	底部说明:"Plus500 по-прежнему привержен ответственной и устойчивой работе во всех аспектах своего бизнеса и считает, что этот подход является одновременно ее обязанностью и важной частью эффективного управления. Plus500 привержен ряду инициатив ESG, направленных на создание ощутимой ценности для наших сотрудников, клиентов, местных сообществ и благотворительных организаций, а также наших акционеров.",
	投资者联系方式:"КОНТАКТЫ ИНВЕСТОРОВ",
	投资者关系主管:"Руководитель отдела по связям с инвесторами",
	AI智慧型量化交易:"Интеллектуальная количественная торговля с использованием искусственного интеллекта",
	订单执行快速可靠:"Выполнение заказа происходит быстро и надежно.",
	全天候自动分析行情:"Автоматический анализ рынка круглосуточно",
	交易受监管低风险:"Торговля регулируется и имеет низкий риск",
	稳定策略高效收益:"Стабильная стратегия и эффективная прибыль",
	取款快速且安全:"Вывод денег быстро и безопасно",
	游戏说明:"Минимальный баланс, необходимый для входа в игру и зарабатывания денег, составляет 10 долларов США.",
}
