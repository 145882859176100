<template>
  <div>
    <layout :isShowFooter="false">
      <template #title>{{ $t('付款方式') }}</template>
      <template #right>
        <img class="addIcon" src="@/assets/icon/add.png" alt=""  @click="bindbank"/>
      </template>
      <template #content>
        <div class="content">       
            <van-cell v-for="item in list" :key="item.id">
              <div class="card" style="color:#ffffff;">
                <div class="rowData">
                  <p>{{ $t('付款方式') }}</p>
                <!--  <p @click="alertBank(item.id, item.type)">{{ $t('修改') }} </p>-->
                </div>
                <div class="cardNumber">{{item.bankcard}}</div>
                <div class="rowData">
                  <p>{{item.name}}</p>
                  <p>{{item.bank}}</p>
                </div>
              </div>
            </van-cell>   
        </div>
		<van-popup v-model="tishi" round closeable @close="closetishi" class="toastIndex">
			  <div class="wt-select-dialog" style=" height: 5.00097rem;text-align: center;background-color: #07183d">
					  <br>
					  <p style="color: #FFFFFF;text-align: center;z-index: 10; font-size: 18px; ">{{$t("请先设置支付密码")}}</p>
			      <van-row >
					<div >
			        <van-col span="12" style="width: 60%; padding-top: 20px;" @click="gosetting">
			          <van-button  type="info" style="width: 5.5rem;background-color: #07183d" >{{$t("去设置")}}</van-button>
			        </van-col>
					</div>
			      </van-row>
			    </div>
			 
		</van-popup>
      </template>
    </layout>
    <ROBOT></ROBOT>
  </div>
</template>

<script>
import ROBOT from '@/components/robot'
import { NavBar,Toast,Popup, Row , Button} from "vant";
import { apiUser_bank_mas,apidelbank,apiVerifyPaypass,apiUser_mas2 } from "@/http/api/";
export default {
  components: {
    ROBOT
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
	  info2:[],
	  tishi:false
    }
  },
   created() {
      const token = localStorage.getItem("key");
      console.log("token", token);
      if (token == null) {
        this.$router.replace("/login");
      }
  	this.token = token;
      apiUser_bank_mas({
        userid: token,
      }).then((res) => {
       
        this.list = res.user;
		
      });
  	apiUser_mas2({
  	userid: token,
  	}).then((res) => {
  	this.info2 = res.user;
	// 判断是否设置交易密码
	if(res.user.paypassword == ""){
		 this.tishi = true;
	}
  	});
    },
  methods: {
	  closetishi(){
		  this.tishi = false;
	  },
	  gosetting(){
	  		this.$router.push('/setPwd');
	  },
	  alertBank(id,type){
		  if(type != 'BANK'){
			  this.$router.push("/bindingPurse");
		  }else{
			  this.$router.push("/bankset?id="+id );
		  }
	  },
	bindbank(){
		this.$router.push("/bindingPurse");
	}
  }
}
</script>

<style lang="scss" scoped>
.addIcon {
  width: 42px;
  height: 42px;
}
.content {
  margin-top: 44px;
  //   padding: 0 10px;
  box-sizing: border-box;
  .card {
    position: relative;
    background: linear-gradient(270deg, #0D6EFD   2%, #084075 100%);
    box-shadow: 0px 19px 26px 1px rgba(0, 0, 0, 0.5);
    // background: linear-gradient(
    //   to bottom,
    //   rgba(156, 138, 39, 0.17),
    //   rgb(0, 0, 0)
    // );
    border-radius: 10px;
    // padding: 38px 34px;
    padding: 0 20px;
    .rowData {
      display: flex;
      justify-content: space-between;
    }
    .cardNumber {
      font-size: 40px;
      font-weight: 700;
    }
  }
}

// .nodata {
//   margin-top: 300px;
//
// }
</style>
