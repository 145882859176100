<template>
  <div class="page">
    <div class="header">
      <div @click="getBack">
        <img class="img" src="../../assets/img/arrow.png" alt="" />
      </div>
      <div class="logg">{{ $t('邀请码') }}</div> 
      <div>
        <!-- <img class="img" src="../../assets/img/添加.png" alt="" /> -->
      </div>
    </div>
    <div class="contnet">
      <div class="bg">
        <!-- <img class="img" src="../../assets/img/建立自己的团队邀请.png" alt="" /> -->
        <div class="tx"></div>
      </div>
      <div class="invitation">
        <div>
          <div ></div>
          <div class="num"></div>
          <!--<div class="clipboardBtnUrl join" @click="onCopy($t('复制成功'))" :data-clipboard-text="info.invit" style="text-align: center;">
           {{ $t("复制") }}
           
          </div>-->
        </div><br><br><Br></Br>
        <!--<div id="qrcode">
          
        </div>-->
      </div>
      <div class="link" style="font-weight:bold;">
        <p>{{ $t('链接点击') }} :</p>
        <p style="color: #ffffff; font-size: 14px;text-decoration: underline;">
          {{yaourl}}
        </p>
        <p>{{$t('截图保存推荐给朋友')}}</p>
      </div>
    </div>
    <ROBOT />
    <!-- <div class="prompt">Cheqam在24小时内取款</div> -->
    <div class="footerBtn">
    
      
	  <van-button 
	              :data-clipboard-text="yaourl"
	              class="clipboardBtnUrl affiliate"
	            
	              @click="onCopy($t('复制成功'))"
	            >
	              {{ $t("复制") }}
	            </van-button>
    </div>
  </div>
</template>

<script>
import ROBOT from '@/components/robot'
import QRCode from "qrcodejs2-fix";
import { Button, Form, Field, Toast } from "vant";
import Clipboard from "clipboard";
import { apiUser_mas2 } from "@/http/api/";

export default {
  name: 'welcome',
  components: {
    ROBOT
  },
  data() {
    return {
      num: '',
      radio: 1,
      inputData: '',
      activeNames: ['1'],
      activeNum: ['0'],
	  info:[],
	  		yaourl:''
    }
  },
  created() {
	 const token = localStorage.getItem("key");
	 console.log("token", token);
	 if (token == null) {
	   this.$router.replace("/login");
	 } 
  	apiUser_mas2({
  	  userid: token,
  	}).then((res) => {
  	  console.log(res);
	  if (res.status == 10) {
	    this.$router.replace('/login')
	  }
  	  this.info = res.user;
	  this.yaourl = "https://www.plus500ai.com/#/register?code="+res.user.invit;
	this.$nextTick(() => {
	   this.code()
	  })
  	});
	
  },
  methods: {
	  code(){
	  		new QRCode("qrcode", {
	  		  width: 91, // 二维码宽度，单位像素
	  		  height: 91, // 二维码高度，单位像素
	  		  text: this.yaourl, // 生成二维码的链接
	  		});
			 if (!isModel()) {
			          const qrCanvs = document.getElementsByTagName('canvas')[0];
			          const qrcBase64 = qrCanvs.toDataURL('image/jpeg'); // 转成jpg
			          const qrcImg = document.querySelector('#qrcode > img');
			          qrcImg.crossOrigin = 'anonymous';
			          qrcImg.src = qrcBase64;
			        }
			
	  	},
		onCopy(text) {
		      let clipboard = new Clipboard(".clipboardBtnUrl");
		      clipboard.on("success", function (e) {
		        Toast(text);
		        e.clearSelection();
		      });
		    },
    // confirmEvent() {
    //   console.log('ceshi')
    // },
    getBack() {
      this.$router.back()
    },
    btnEvent() {
      console.log('dsfs')
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  padding: 0 0px 80px 0px;
  background:#07183d;
  .header {
    padding: 0 10px;
    height: 56px;
    line-height: 56px;
    display: flex;
    justify-content: space-between;

    .img {
      width: 40px;
	  margin-top:15px;
    }
    .logg {
      font-size: 40px;
      font-family: PingFang SC-中粗体, PingFang SC;
      font-weight: normal;
      color: #ffffff;
    }
  }

  .contnet {
    .bg {
      height: 700px;
	  
      background: url('../../assets/img/jianli.png') no-repeat center center;
      background-size: 100% 100%;
      .tx {
        padding-top: 500px;
        padding-left: 50px;
        font-size: 40px;
        color: #ffffff;
      }
    }
    .invitation {
      display: flex;
      justify-content: space-between;
      padding-bottom: 80px;
      margin: -280px 50px 0;
      color: #ffffff;
      // border-bottom: 1px solid #888;
      .join {
        // height: 40px;
        color: #ffffff;
        border-radius: 50px;
        padding: 20px 30px;
        width: 130px;
        margin-top: 40px;
        background-color: #0d9135;
        .arrow {
          width: 40px;
          height: 10px;
          margin-left: 30px;
        }
      }
      .num {
        margin-top: 20px;
        font-size: 40px;
      }
      .qrCode {
        width: 280px;
        height: 220px;
        // background-color: #fff;
      }
    }
    .link {
      margin: 50px 50px 190px;
      color: #888;
    }
  }
  .footerBtn {
    margin: 0 50px;
    display: flex;
    text-align: center;
    justify-content: space-between;
    .affiliate {
      flex: 1;
	  background-color: #0D6EFD  ;
      border-radius: 10px;
      color: #ffffff;
	  font-size:25px;
	  font-weight:bold;
      padding: 30px 40px;
	  box-shadow: 0px 10px 20px #0D6EFD;
      border: 1px solid #ffffff;
    }
    .pay {
      flex: 1;
      font-size: 30px;
      margin-left: 30px;
      border-radius: 50px;
      padding: 30px 40px;
      background-color: #0d9135;
    }
  }
}
</style>
