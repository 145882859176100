<template>
  <div class="page">
    <div class="header">
      <div @click="getBack">
        <img class="img" src="../../assets/img/arrow.png" alt="" />
      </div>
      <div class="logg">{{ $t('添加银行卡') }}</div>
      <div>
        <!-- <img class="img" src="../../assets/img/添加.png" alt="" /> -->
      </div>
    </div>
    <div class="contnet">
      <div class="card">
        <p class="text">{{ $t('银行名称') }}</p>
        <van-field class="input" readonly clickable :value="value" :placeholder="$t('请选择银行')" @click="showPicker = true" />
        <van-popup v-model="showPicker" round position="bottom">
          <van-picker show-toolbar :columns="columns" @cancel="showPicker = false" @confirm="onConfirm" :confirm-button-text="$t('确认')" :cancel-button-text="$t('取消')">
            <template #option="item">
              <div class="bank">
                <van-image  v-if="item.includes('(THB)')" height="30" width="30" :src="require('@/static/images/bank/'+item.replace(/\(THB\)/,'')+'.png')"></van-image>
                <van-image  v-else-if="item.includes('(IDR)')" height="30" width="60" :src="require('@/static/images/bank/IDR/'+item.replace(/\(IDR\)/,'')+'.png')||''"></van-image>
                <span class="code">{{item}}</span>
              </div>

            </template>

          </van-picker>
        </van-popup>
        <p class="text">{{ $t('姓名姓氏') }}</p>
        <van-field class="input" v-model="name":placeholder="$t('请输入你的名字')" />
        <p class="text">{{ $t('银行卡号') }}</p>
        <van-field class="input" v-model="card" :placeholder="$t('请输入银行卡号')" />
        <!--<p class="text">{{ $t('备注') }}</p>
		<van-field class="input" v-model="remark" :placeholder="$t('备注号码类别')" />-->
		
        <div class="description">
          <div>
            {{ $t('* 重要提示:借记卡信息必须真实有效才能取款。') }}
          </div>
        </div>
      </div>
    </div>
    <van-popup v-model="paypwd" round closeable @close="closepaypwd">
      <div class="wt-select-dialog" style=" height: 8.00097rem;text-align: center;background-color: #2a2c2e">
        <br>
        <div class="wt-dialog_bd">

          <p style="color: #FFFFFF;text-align: center;z-index: 10; font-size: 18px; ">{{$t("请输入交易密码")}} <span style="color: #26B77E;"></span></p>
          <div class="wt-card" style="min-height: 50px;text-align: center;">
            <van-field class="input" v-model="paypasswd" :placeholder="$t('提款密码')" />
          </div>
          <van-row>

            <div>
              <van-col span="12" style="width: 90%; padding-top: 20px;">
                <van-button type="info" style="width: 5.5rem;background-color: #2a2c2e" @click="saveBank">{{
		            $t("确认")
		          }}</van-button>
              </van-col>
            </div>
          </van-row>
        </div>
      </div>
    </van-popup>
    <!-- <div class="prompt">Cheqam在24小时内取款</div> -->
    <div class="footerBtn">
      <HBBTN @clickEvent="confirmEvent">
        {{ $t('确认') }}
      </HBBTN>
    </div>
    <ROBOT></ROBOT>

  </div>
</template>

<script>
import ROBOT from '@/components/robot'
import HBBTN from '@/components/hb-btn'
import { NavBar, Button, Field, Popup, Picker, Toast, Row, Col } from "vant";
import { apiUser_bank_type, apiBank_add_ajax } from "@/http/api/";
import md5 from 'js-md5';
export default {
  name: 'welcome',
  components: {
    HBBTN,
    ROBOT,
    [Popup.name]: Popup,
  },
  data () {
    return {
      name: '',
      card: '',
      name: '',
      inputData: '',
      columns: [],
      showPicker: false,
      paypwd: false,
      paypasswd: "",
      remark: "",
      value: ""
    }
  },
  created: function () {
    // `this` 指向 vm 实例

    const token = localStorage.getItem("key");
    console.log("token", token);
    if (token == null) {
      this.$router.replace("/login");
    }
    this.userid = token;
    apiUser_bank_type({
      userid: token,
    }).then((res) => {
      console.log(res);
      this.columns = res.info;
    });
  },
  methods: {
    confirmEvent () {

      this.paypwd = true;
    },
    closepaypwd () {
      this.paypwd = false;
    },
    getBack () {
      this.$router.back()
    },
    saveBank () {
      if (!this.card) {
        Toast.fail(this.$t("不能为空"));
        return;
      }
      if (!this.value) {
        Toast.fail(this.$t("不能为空"));
        return;
      }
      if (!this.name) {
        Toast.fail(this.$t("不能为空"));
        return;
      }
      if (!this.remark) {
        // Toast.fail(this.$t("不能为空"));
        // return;
      }


      apiBank_add_ajax({
        userid: this.userid,
        name: this.name,
        card: this.card,
        bank: this.value,
        remark: this.remark,
        paypasswd: md5(this.paypasswd),

      }).then((res) => {
        console.log(res);

        if (res.status) {
          setTimeout(() => {
            Toast.success(this.$t(res.info));
            this.$router.push("/bankCard");
          }, 1000);
        } else {
          Toast.fail(this.$t(res.info));
        }
      });
    },
    onConfirm (value) {
      this.value = value;
      this.showPicker = false;
      if (value == "USDT") {
        this.$router.push("/bankCard");
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.page {
  padding: 0 30px 40px 40px;
  background:#07183d;
  .header {
    padding: 0 10px;
    height: 56px;
    line-height: 56px;
    display: flex;
    justify-content: space-between;

    .img {
      width: 40px;
	  margin-top:15px;
    }
    .logg {
      font-size: 40px;
      font-family: PingFang SC-中粗体, PingFang SC;
      font-weight: normal;
      color: #ffffff;
    }
  }
  .contnet {
    margin-top: 42px;
    width: 100%;
    border-radius: 10px;
    background: linear-gradient(270deg, #0D6EFD   2%, #084075 100%);
    .card {
      padding: 40px 26px;

      .text {
        color: #888;
      }
    }
    .description {
      color: darkgray;
      line-height: 30px;
      letter-spacing: 1px;
    }
  }
  .footerBtn {
    margin-top: 50px;
  }
  .bank{
    display: flex;
    align-items: center;
    .code {
    margin-left: 20px;
  }
  }

}
</style>
