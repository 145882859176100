export default {
  tabbar: {
    首页: '首页',
    市场: '市场',
    任务: '任务',
    团队: '团队',
    我的: '我的'
  },
  navbar: {
    充值: '充值',
    提现: '提现',
    帮助: 'FAQ',
    分享: '邀请',
	推广: '推广',
    活动: '游戏',
	游戏: '游戏',
  },	 
  payment: {
    付款方式详情: '付款方式详情',
    银行卡: '银行卡',
    钱包地址: '提现方式',
    交易密码: '交易密码',
    保存: '保存'
  },
  market: {
    市场: '市场',
    名称: '名称',
    价格: '价格',
  },
 
  task: {
    任务: '任务',
	我的余额: '我的余额',
    增加订单数量: '增加订单数量',
    今日剩余订单: '今日剩余订单',
    频率: '频率',
    手动交易: '手动交易',
    预期收益: '预期收益',
    需要启动存款: '需要启动存款',
    任务说明: '任务说明',
    每天可以完成10个任务: '每天可以完成10个任务',
    联系VIP助手增加任务数量: '联系VIP助手增加任务数量',
    每个用户的任务总数为300次: '每个用户的任务总数为300次',
    购买机器人无需人工操作即可自动交易: '购买机器人无需人工操作即可自动交易',
    我知道了: '我知道了'
  },
  邀请好友注册领取佣金:"邀請好友註冊領取佣金",
  领取:"领取",
  未满足条件:"未满足条件",
  总收入:"总收入",
  投资建议:"※进行复利投资计划，收益更可观",
  语言切换: '语言切换',
  POS区块节点挖矿: '人工智能量化交易详情',
  节点类型: '机器人\n类型',
  加入价格: '购买\n价格',
  质押资金: 'POS\n资金',
  质押周期: '周期\n(天)',
  每日收入: '每日\n收入',
  累计积分: '累计\n积分',
  代理等级: '代理\n等级',
  下级折扣比: '下级\n折扣',
  代理折扣比: '代理\n折扣',
  下属等级: '下属\n等级',
  代理佣金比率: '代理\n佣金\n比率',
  下属每日收入: '下属\n每日\n收入',
  代理佣金: '代理\n佣金',
  引用对象: '引用\n对象',
  积分等级折扣规则: '积分等级折扣规则',
  积分等级折扣规则介绍: '成功参与Ai量化交易的用户将获得对应的账户积分，并且他们的代理上级用户也可以增加对应的积分（适用于直属下属15%），当账户总积分满足对应条件后代理账号的等级将会提升，不同级别的代理账号可以获得不同比例的价格折扣优惠。相关积分折扣详情如下：',
  积分等级折扣规则介绍2: '具有不同关系（直接）的用户将收到根据比例给下属参与Ai量化交易的折扣和代理级折扣。 其他高层也将获得根据自己代理相应比例的折扣等级。 参与Ai量化交易价格优惠：拥有对应代理等级的用户参与Ai量化交易时将获得相应的代理级优惠权利',
  团队: '团队',
  代理佣金制度: '代理佣金制度',
  代理佣金说明详情: 'A获得的LV1代理比例：8%，A获得的LV2代理比例：3%，A获得的LV3代理比例：2%。例子：B、C、D每日盈利为：100 USD，A获得的LV1代理奖励：8%*100=8 USD。A获得的LV2代理奖励：3%*100=3 USD。A获得的LV3代理奖励：2%*100=2 USD。总计 佣金收入为：8+3+2=13 USD。',
  代理结构图示: '代理结构图示',
  代理结构图示说明: 'A代表你， A邀请B(A的下属是B)，B邀请C(B的下属是C)，C邀请D(C的下属是D)。 A可以有多个下属(B1,B2,B3,B4,B5,B6,etc.)，B(B1,B2,B3) 也可能有多个下属(C1,c2,c3,c4,c5,c6,etc.) C... D...',
  高级代理佣金说明: '高级代理佣金说明',
  高级代理佣金说明详情: 'A 代表你， A邀请B(A 的下属是 B)， B 邀请 C(B 的下属是C)， C 邀请 D (C的下属是D) 。上级代理将根据本身账号和下属的账号参与节点挖矿的总金额 比率获得不同的代理佣金收入(%)。 A对比下属成员每日收入获得代理奖励相应的比例。 参与的节点必须处于正常活动状态。 代理收入由INGSAI MINING提供所有财务支出，并不会影响正常下属成员的每日收入。',
  高级代理佣金说明详情2: '例子：高级代理总参与挖矿金额(A)>30%下层会员总挖矿金额(B/C/D)(例：A>30% of B/C/D)',
  积分: '积分',
  规则: '规则',
  会员级别: '会员级别',
  经验: '积分总值',
  代理信息: '成员信息',
  更多: '更多',
  团队奖励: '团队佣金',
  昨天: '昨天',
  本星期: '本星期',
  全部: '全部',
  机器人说明: 'Plus500Ai的使用者可以同时购买多个量化交易套餐，NO.1机器人每个帐户只能购买10次。谨记：购买量化机器人后不要忘记点击激活策略，否则将没有收入。量化机器人有效时间按购买时间计算，请及时激活量化交易策略，如果量化机器人过期后还未激活,您将失去您的购买量化交易策略套餐的成本!!! 请所有参与Plus500Ai量化交易的用户仔细阅读此条例！！',
  会员体验: '会员体验',
  机器人采购: '机器人购买',
  机器人启动押金: '节点POS金额',
  机器人礼物: '节点礼物',
  机器人订单: '节点订单',
  一次性总和: '显示',
  总计: '总计',
  
  被激活: '已POS',
  工作中: '已激活',
  暂停: '暂停POS',
  结束: '已到期',
  刷新: '刷新',
  来源: '来源',
  价格: '量化套餐价格',
  启动押金: 'POS金额',
  剩余时间: '剩余时间',
  激活时间: '激活时间',
  号: '号',
  我的资产: '我的资产',
  退出: '登出',
  邀请码: '邀请码',
  我的钱包余额: '我的钱包余额',
  我的机器人仓库: '我的机器人列表',
  收益面板: '收益面板',
  去提款: '提款',
  昨天代理返利: '昨天代理佣金',
  累计代理返利: '累计代理佣金',
  今天机器人收益: '今天交易收益',
  累计机器人收益: '累计交易收益',
  累计机器人回扣: '累计交易佣金',
  累计总回报: '累计总收益',
  自动订单机器人数量: '已激活的量化交易机器人',
  我的付款方式: '我的付款方式',
  个人收入记录: '个人收入',
  团队收入记录: '团队收入',
  记录详情:'记录详情',
  充值记录: '充值记录',
  我的团队成员: '我的团队成员',
  活动中心: '规则介绍',
  修改登录密码: '登陆密码设置',
  交易密码管理: '交易密码设置',
  机器人: 'Ai交易策略',
  机器人性能: 'Ai机器人面板',
  机器人价格: '交易策略价格',
  有效期: '交易有效期',
  钱包地址: '钱包地址',
  天: '天',
  去购买: '购买',
  买个机器人: '购买量化交易机器人',
  我的钱包余额: '我的账户余额',
  预计日收益: '预计日收益',
  启动保证金: '启动保证金',
  请输入购买数量: '请输入购买数量',
  个机器人: '量化交易机器人',
  机器人购买后需要激活才能工作: '购买节点后需要POS对应金额才能开始获益',
  机器人激活需要从余额中扣除相应的启动押金:
    '节点POS挖矿需要从账户余额中扣除相应的POS金额',
  机器人暂停机器人停止时将返还启动押金不活跃的机器人可以赠送:
    '暂停节点POS挖矿时可以立刻退回POS的金额',
  购买: '购买',
  点击去了解我们: '量化交易',
  购买机器人: '购买机器人',
  快速入口: '快速入口',
  '日/个人受益': '日/个人受益',
  购买教程: 'AI交易机器人列表',
  激活金额: 'POS费',
  订单数量: '订单数量',
  我们的合作伙伴: '我们的加密市场合作伙伴',
  提款: '提款',
  提款金额: '提款金额',
  你还没有添加银行卡: '你还没有添加银行卡',
  提现说明: '提现说明',
  说明1: '1.您可以一键选择或手动输入充值金额',
  说明2: '2.推荐使用USDT(TRC20)，转账手续费最低',
  说明3: '3.客户服务时间为中美洲时间周一至周五AM8至PM6',
  说明4: '使用USDT充值的用户需要上传正确的转账成功截图和TXID，平台审核通过后会成功到账',
  说明5: '4.最低充值8 USDT,方便系统快速审核处理到账',
  说明6: '5.如您对充值有任何问题请及时联系在线客服协助处理',
  确认提款: '确认提款 ',
  请输入手机号码:'手机号码',
  请输入提现金额: '提现金额 ',
  提现费:'提现费',
  提现须知: '请仔细阅读提款规则',
  TIPS1: '1:每日取款审核时间为周一至周五AM8至PM6。周末可以提款，提款审核时间为周一',
  TIPS2: '2:7X24小时制，随时取款，最迟24小时到账，最短5分钟到账',
  TIPS3: '3:在线客服服务时间：周一至周五AM8至PM6',
  TIPS4: '4:由于USDT转账需要成本，用户提款时平台收取提款费',
  TIPS5: '5:最低提款金额为10 USDT',
  钱包余额: '钱包余额',
  输入数量:"输入数量",
  数量:"数量",
  手机号码:"手机号码",
  手机号码已存在:"手机号码已注册",
  邮箱:"邮箱",
  输入手机号码: '输入手机号码',
  请输入登录密码: '登录密码',
  登录: '登录',
  输入账号: '输入账号',
  密码: '密码',
  点击注册: '还没有帐户？立即创建一个！',
  忘记密码: '忘记密码',
  重新登录: '重新登入',
  密码找回成功: '密码找回成功',
  找回成功请重新登录: '找回成功 请重新登录',
  确认: '确认',
  发送验证码: '发送验证码',
  再次输入密码: '确认密码',
  输入验证码: '输入邮箱CAPTCHA',
  输入手机验证码:"输入手机验证码",
  输入手机号: '输入手机号',
  输入密码: '登入密码',
  快速注册: '快速注册',
  注册: '注册',
  立即登录: '已有账号，点击登陆',
  请输入邮箱: 'Gmail邮箱地址',
  输入用户名: '输入用户名',
  请输入邀请码: '请输入邀请码',
  返回: '返回',
  密码找回失败: '密码找回失败',
  输入的信息有误: '输入的信息有误',
	获取验证码:"获取验证码",
	手机号码不能为空:"手机号码不能为空",
	账号不存在:"账号不存在",
	发送成功:"发送成功",
	今日收益:"（当前收入）",
	"您好如果您中途终止POS-STAKING系统将收取5%的STAKING费用":"您好，如果您中途终止POS，系统将收取5%的POS管理费用。特别提醒：体验的免费节点暂停POS后将无法再次获取，也无法获得任何费用！！",
	我同意:"我同意",
	用户账号ID:"账号ID",
	取消:"取消",
	日收益:"日收益",
	请输入购买数量:"请输入购买数量",
	数量不能为空:"数量不能为空",
	加载中:"加载中...",
	唯一码:"机器人编码",
	未激活:"未激活",
	激活:"激活策略",
	购买时间:"购买时间",
	"钱包余额不足，激活该机器人失败":"账户余额不足，无法进行节点POS挖矿",
	
	运行时长:"运行时长",
	签约购买:"余额购买",
	系统赠送:"系统赠送",
	状态:"状态",
	正常:"正常",
	我的机器人:"我的量化交易机器人",
	一级会员:"一级",
	二级会员:"二级",
	三级会员:"三级",
	人:"",
	充值客服:"充值客服",
	充值:"充值",
	提现:"提现",
	提款密码:"提款密码",
	设置交易密码:"设置交易密码",
	登录密码:"登录密码",
	请输入交易密码:"输入交易密码",
	再次请输入交易密码:"确认交易密码",
	确认: '确认',
	手机号码不能为空:"手机号码不能为空",
	两次密码不一致:"两次密码不一致",
	请输入验证码:"请输入验证码",
	操作成功:"操作成功",
	"用户名或密码不正确,登录失败":"用户名或密码不正确,登录失败",
	登录成功:"登录成功",
	充值说明:"充值说明",
	请先设置支付密码:"请先设置交易密码",
	复制成功:"复制成功",
	冻结机器人做单本金:"冻结量化资金",
	待审核:"待审核...",
	成功:"成功",
	失败:"失败",
	审核中:"审核中",
	在线充值:"在线充值",
	描叙:"描述",
	POS节点挖矿LV0:"POS-LV0节点挖矿",
	POS节点挖矿LV1:"POS-LV1节点挖矿",
	POS节点挖矿LV2:"POS-LV2节点挖矿",
	POS节点挖矿LV3:"POS-LV3节点挖矿",
	POS节点挖矿LV4:"POS-LV4节点挖矿",
	POS节点挖矿LV5:"POS-LV5节点挖矿",
	银行卡提现:"银行卡提现",
	USDT提现:"USDT提现",
	三级代理:"三级代理",
	一级代理:"一级代理",
	二级代理:"二级代理",
	一级:"一级",
	二级:"二级",
	三级:"三级",
	做单:"量化交易",
	登录失败:"登录失败",
	请勿重复操作:"请勿重复频繁操作",
	邀请码不正确:"邀请码不正确",
	团队返佣:"团队佣金",
	购买机器人返佣:"机器人返佣",
	本金返还:"POS返还",
	佣金收入:"佣金收入",
	时间:"时间",
	机器人做单返还:"POS金额返还",
	涨幅:"涨幅",
	市场:"市场",
	客服:"服务",
	验证码错误:"验证码错误",
  付款方式:"提款方式",
	我的留言:"我的留言",
  暂无数据: '暂无数据',
  银行卡:'银行卡',
  修改: '修改',
 '请确保您的钱包地址正确，如果您的地址输入错误而导致收不到钱，请及时联系在线客服协助':'请确保您的钱包地址正确，如果您的地址输入错误而导致收不到钱，请及时联系在线客服协助',
 连接借记卡:"连接借记卡",
 银行名称:'银行名称',
 姓名姓氏: '姓名',
 IBAN号码:'IBAN 号码',
 '* 重要提示:借记卡信息必须真实有效才能取款。':'* 重要提示:借记卡信息必须真实有效才能取款。',
 帮助中心:'帮助中心',
 会员须知:'会员须知',
 '24小时内使用USDT实时取款（推荐）':'1. 24小时内使用USDT实时取款（推荐）',
 '银行卡 取款 *取款时间为10:00-18:00 *发起取款后2小时内入账*申请非取款时间或跨行取款将于次日10:00-18:00到账':' 2.银行卡 取款 *取款时间为10:00-18:00 *发起取款后2小时内入账*申请非取款时间或跨行取款将于次日10:00-18:00到账',
 我知道了:'我知道了',
 链接点击:'邀请注册链接：',
 确定:'确定',
 建立自己的团队邀请:'建立自己的团队邀请',
 此账号已冻结:"此账号已冻结",
 手机号存已存在:"手机号存已存在",
 注册账号已存在:"注册账号已存在",
 请确定新密码:"请确定新密码",
 请再次输入登录密码:"请再次输入登录密码",
 密码长度不能少于6位:"密码长度不能少于6位",
 加入:'加入',
 "你出售的机器人对方已接受,出售所得已打款到您账户,请查收。":
 	    "你出售的节点对方已接受,出售所得已打款到您账户,请查收。",
 	"你出售的机器人对方已拒绝接受":"你出售的节点对方已拒绝接受",
 	向你赠送一台机器人:"向你赠送一个节点，待确认",
 	向你出售一台机器人:"向你出售一个节点，待确认",
 	出售价格不能超过机器人价格:"出售价格不能超过节点价格",
 	银行卡已被绑定:"银行卡已被绑定",
 	USDT_TRC20提现:'USDT_TRC20提现',
 	USDT_ERC20提现:'USDT_ERC20提现',
 	银行卡提现:'银行卡提现',
 	邀请赠送:'邀请奖金',
 	机器人返佣:'机器人返佣',
 	升级:'成功购买',
 	充值主网:'充值主网',
   "您被限制出售机器人,请联系客服了解详情":
     "您被限制出售节点,请联系客服了解详情",
   交易编号: "交易序号",
   消息详情:"消息详情",
   个人收益累计:"个人收益累计",
   今日代理收益:"今日代理收益",
   代理累计收益:"代理累计收益",
   截图保存推荐给朋友:"分享您的推荐注册链接邀请好友加入Plus500Ai平台赚取团队收入佣金奖励。 三级团队成员收益提成8%-3%-2%，团队佣金按照下级成员每日收入数据，每日00:00后自动结算进入您的账号余额",
   复制:"复制",
   充值提示1:"提示1:最低充值5 USDT起,即可充值,否则不到账",
   充值提示2:"提示2:充值需要链上确认，成功充值后，预计一分钟左右到账钱包余额。",
   请输入正确的充值金额:"充值金额≥8 USDT",
   推荐:"推荐",
   充值金额:"充值金额",
   请上传付款截图:"请上传付款成功截图",
   交易号: '交易Hash号',
   充值ID提示:"请黏贴你的Txid交易序列号",
   "请确保钱包地址无误，如因钱包地址填写错误导致无法收款而造成的损失，将有用户自己承担。":"请确保钱包地址无误，如因钱包地址填写错误导致无法收款而造成的损失，将由用户自己承担。",
	上传凭证:"上传付款凭证",
	充值确认: "提交",
	不能为空:"不能为空",
  绑定钱包地址:'绑定钱包地址',
	备注:"备注",
	请输入银行卡号:"请输入银行卡号",
	请输入你的名字:"请输入你的名字",
	银行卡号:"银行卡号",
	添加银行卡:"添加银行卡",
	请选择银行:"请选择银行",
	请输入钱包地址:"请输入钱包地址",
	钱包地址:"钱包地址",
	"密码错误次数过多,请等待x秒后再试":"密码错误次数过多,请等待600秒后再试",
	"此账号已冻结,请联系客服":"此账号已冻结,请联系客服",
	手机号格式错误:"手机号格式错误",
	"取款须知":"取款须知",
	"须知1":"1. 24小时内使用USDT-TRC20实时取款（推荐）",
	"须知2":"2. 周六周日提款",
	"须知3":"*用户周六周日可以提款",
	"须知4":"*周末取款将于周一10AM-8PM到账",
	我知道了:"我知道了",
	"提现0":"1USDT=1USD ERC20",
	"提现1":"提示1：最低单笔提现5USDT/天，单笔交易手续费5USDT/次",
	"提现2":"TRC20提示2：每次提款最低5USDT，每次交易手续费1USDT。",
	"提现3":"银行账户提示3：最低单次取款为$5，交易手续费为交易金额的$5%",
	"提现4":"提现时间：提现申请提交后24小时内到账",
	
	已绑定:"已绑定",
	去设置:"去设置",
	注册成功:"注册成功",
	汇率:"汇率",
	请输入正确的提现金额:"请输入正确的提现金额",
	提款记录:"提款记录",
	"绑定的钱包地址必须跟付款钱包一致，否则不会到账。":"tip1:绑定的钱包地址必须跟付款钱包一致，否则不会到账。",
	"充值成功后预计在一分钟左右到账，请检查钱包约。":"tip2:充值成功后预计在一分钟左右到账，请检查钱包余额。",
	请先绑定钱包地址:"请先绑定钱包地址",
	输入邮箱:"输入邮箱",
	邮箱已存在:"邮箱已存在",
	注册邮箱已存在:"注册邮箱已存在",
	指定用户不存在:"指定用户不存在",
	今日涨幅:"涨跌幅",
	恭喜新用户:"恭喜新用户",
	快捷充币:"存款",
	快速买USDT:"USDT充值",
	在线支付:"在线支付",
	自动到账:"自动到账",
	线下支付:"线下支付",
	联系客服获取验证码:"联系客服获取验证码",
	获取:"获取",
	退出成功:"退出成功",
	不能低于最小充值:"不能低于最小充值",
	不能低于最小提现金额:"不能低于最小提现金额",
	最小提现:"最小提现",
	设置成功:"设置成功",
	官方充币:"官方充币",
	银行卡充值:"银行卡充值",
	等待审核:"等待审核",
	可提现金额不足:"可提现金额不足",
	未经授权:"未经授权",
	交易密码不正确:"交易密码不正确",
	提现次数不足:"提现次数不足",
	官网:"官网",
	账户usdt不足:"账户USDT余额不足",
	激活成功: "POS成功",
	操作成功: "操作成功",
	修改成功:"修改成功",
	注册说明:"手机OTP和Email CAPTCHA 只需任选1种方式验证即可成功注册",
	弹窗内容:
	"欢迎来到Plus500Ai 量化交易平台，每位用户可以使用注册奖金1 USDT购买NO.0量化机器人交易策略套餐，有效期1天，免费赚取0.5 USDT。其他量化交易策略套餐都需要对应费用才能使用。用户购买机器人后需要激活量化交易策略才能自动开始运行，每个交易策略对应不同的有效期限。如果用户购买对应机器人点后没有点击激活策略 ，您将不会获得任何交易收益。",
	标题1:'1.Plus500平台介绍',
	标题2:'2.Ai量化交易的优点',
	标题3:'3.为什么选择Plus500Ai?',
	标题4:'4.如何加入Plus500Ai',
	标题5:'5.Plus500Ai市场前景',
	标题6:'6.Plus500Ai收益模式',
	标题7:'7.充值与提现条例',
	标题8:"大逃杀游戏玩法",
	内容8:"Plu500Ai平台的大逃杀游戏中有8个房间，玩家在8个房间中任意选择一个房间投放金币，在系统倒计时结束后，游戏系统会出现一个杀手，杀手随机进入一个房间消灭掉房间的人，其他玩家便可以瓜分这个房间的金币，获得盈利。 大逃杀游戏的玩法比较短，一局只有几分钟，玩家能够在闲暇时间玩。瓜分金额 = 本期杀手杀掉的房间总额。实际瓜分金额 = 瓜分金额 * 0.9（扣除销毁的比例为0.1）。瓜分比例 =  实际瓜分金额 / 其他房间总投注金额。中奖金额 = 瓜分比例* 实际投注金额。1金币=1USDT, 最低需要10 USDT才能参与大逃杀游戏。",
	内容1:'Plus500交易平臺由Plus500SEY Ltd提供。因此，Plus500SEY Ltd是本網站所述或提供的金融產品的發行人和銷售商。Plus500SEY Ltd是一家總部位於塞舌耳的公司（公司 編號 8426415-1）其辦事處位於維多利亞。本公司經塞舌耳金融服務管理局（FSA）授權和監管，許可證號為SD039，提供各種基礎產品的差價合約（CFD）。本公司是一家快速發展的CFD供應商，目前提供超過2800種金融工具的投資組合。Plus500SEY Ltd是Plus500 Ltd的子公司，Plus500 Ltd已在倫敦證券交易所的主要市場上市，總部設在海法。',
	内容2:'AI全自动量化交易的优势就是用理性的工具按模型设定去操作交易，机器人没有情绪变化，能克服人性、人工在操作过程中的贪婪与恐惧及其他很多不足和弱点。 人工操作往往会受到大盘涨跌及其他各种各样的因素影响，不能长期按照最好的盈利模式去操作。',
	内容3:'Plus500Ai透過將核心產品擴展到新市場和現有市場，推出新的交易和金融產品，並深化與客戶的接觸，Plus500作為一家多資產金融科技集團的地位逐漸加強。保护您的资产，使用行业领先的安全技术保护您的加密密钥。Plus500Ai还提供全局托管，这意味着我们受到监管和审计，最高可达 2.5 亿美元的保险。部署您的资金，目标不仅仅是持有资产，而是让它们发挥作用。Plus500Ai允许您以多种方式部署您的资金，从 Ai量化交易 到经典的主要服务，取决于您的风险承受能力。在线平台，无需雇用额外的区块链工程师，即可为您自己的用户提供更多服务。',
	内容4:'Plus500Ai成立以来，只有一个官网WWW.PLUS500AI.COM。 所有的用户需登录官网下载APP,如其它网址均不是本公司的。 注册需提交用户的手机号码以及邮箱和推荐者的邀请码即可快速注册。 所有与客户的沟通均以书面形式进行，可以是透过电子邮件或线上聊天。 若要透过电子邮件联络Plus500，请填写“联络我们”页面上的表格（“申请表”）。 提交申请表后，Plus500会透过电子邮件直接发送回复到您在申请表中指定的邮箱地址。 如有疑问请联络客服。',
	内容5:'Plus500Ai跨产品、设备和国家/地区运营，为客户提供可靠和直观的金融交易机会,我们一贯追求卓越的产品和团队。 凭借先进的专有技术和以客户为中心的方法，我们的用户可以获得一流的交易服务体验。',
	内容6:'Plus500Ai由高级数据分析师团队提供专业的加密货币市场量化交易策略，参与投资购买Ai量化交易机器人的用户可以依靠和机器人配套的交易策略获得量化交易利润。Ai 量化交易机器人根据Plus500Ai的市场交易策略循环分析最优交易时间，通过低级买进高价卖出的模式获得量化交易利润。Plus500Ai每日为投资的用户提供最优量化交易策略，平台抽取用户量化收益利润的5%作为平台运营资金。',
	内容7:'Plus500Ai目前支持USDT充值与提现，如后续开通其它通道官网会有公告。最低充值和提款均为10 USDT,充值与提现最快5分钟，最慢24小时内。用户提款时间没有限制，您可以随时提款。提款在正常工作日5分钟-24小时内到达。周末提款统一安排至周一处理。',
	BSC提现:"USDT_BSC提现",
	社区Ads收益:"社区Ads收益",
	Ads收益区间:"Ads收益1-1000$",
	申请Ads费用:"申请Ads费用",
	Ads促销地址:"Ads促销地址",
	填写Ads促销地址:"填写Ads促销地址",
	申请Ads费用须知:"申请Ads费用须知",
	须知A:"1. 用户录制促销视频上传发布到自己的YouTube，Facebook,频道或其他社区进行Ads促销，申请$1-$1000奖金，提交申请3天后平台将审核您的Ads视频素材，并根据视频促销效果将Ads奖金打入您的账户余额，无任何促销效果的视频无法通过审核",
	须知B:"2. 同一频道同一Ads促销广告视频只能申请一次奖金，每周可发布一次新的促销视频素材，不要恶意重复发送请求。 如果您恶意多次在平台上提交非本人制作的Ads请求或者审核失败的Ads素材，系统检测后您的帐户将被禁止使用",
	须知C:"3. 用户录制的Ads视频素材需要有基本的平台介绍和平台功能解说，视频时间不短于5分钟，您的频道订阅者数量越多，视频的促销效果越好，可以申请的Ads费用越高。具体Ads费用参考如下，以YOUTUBE为例：",
	须知D: "订阅者0-2K:申请金额1-20$",
	须知D1:"订阅者3K-10K:申请金额21-40$",
	须知D2:"订阅者11K-30K:申请金额41-100$",
	须知D3:"订阅者31K-50K:申请金额101-300$",
	须知D4:"订阅者51K-100K:申请金额301-1000$",
	须知D5:"4. 系统将将于每周日对Ads奖金申请进行审核处理，符合条件的用户将收到系统派送的奖金",
    请求审核:"请求审核",
	申请金额:"申请金额",
	URL促销地址:"URL促销地址",
	提交时间:"提交时间",
	需要更新的银行卡不存在:"需要更新的银行卡不存在",
	银行卡提现:"银行卡提现",
	预计收入:"预计收入",
	总投资:"总投资",
	购买价:"购买价",
	邀请:"邀请",
	成员:"成员",
	积分等级折扣规则介绍3:"该折扣金额仅有效期至2023年12月31日，2024年1月1日后将有新的调整。",
	LV1佣金:"LEVEL1佣金",
	LV2佣金:"LEVEL1佣金",
	LV3佣金:"LEVEL1佣金",
	合格条件:"每人最低充值8美元",
	随时可退:"随时可退",
	登录密码错误:"登录密码错误",
	充值奖金:"充值USDT≥50USDT,奖金3%",
	充值奖金1:"充值IDR≥50USDT,奖金2%",
	充值奖金2:"充值THB≥50USDT,奖金5%",
	不能重复发送短信:"10 分钟内不能重复发送短信。 建议您使用EMAIL CAPTCHA直接注册。",
	限购说明1:"您好，NO.0 量化机器人交易策略套餐每个用户只能购买一次",
	活动已过期:"活动已过期",
	修改银行提示:"提款银行账户绑定后无法修改，如需协助，请联系客服",
	修改钱包地址提示:"USDT提现地址绑定后无法修改，如需协助，请联系客服",
	注册推送消息1:"欢迎来到INGSAI，新用户启动POS 挖矿节点后，每5分钟获得一次POS 挖矿收入。团队佣金收入每日00.00左右自动进入账户余额",
	限购说明:"您好，该类型量化机器人套餐最大购买次数为10次，建议您购买其他类型量化交易套餐",
	注册协议:"按‘创建帐户’，即表示您确认您已年满18周岁。 您已知悉Plus500AI可能会使用您的电子邮件地址或其他个人资料以电子方式与您联系，提供其产品/服务的相关资讯。 我们也可能会通知您市场事件，帮助您最大限度地利用您的交易经验。 您可以在平台上的'通知设置'和‘隐私设置’选项卡中管理和修改通知首选项。 您可以随时取消订阅这些资讯。 更多资讯，请查阅我们的‘隐私协议’",
	公司说明1:"Plus500AI是Plus500 Ltd 的注册商标。 Plus500 Ltd透过以下子公司进行运营:",
	公司说明2:"Plus500SEY Ltd由塞舌耳金融服务管理局授权和监管（许可证 号 SD039）。",
	公司说明3:"Plus500SEY Ltd是本网站上所描述或可用金融产品的发行人和卖方。",
	公司说明4:"Plus500UK Ltd 由金融行为监管局授权和管理。 金融行为监管局 注册号码：509909. 加密货币CFD未向散户用户提供。",
	公司说明5:"Plus500CY Ltd受塞浦路斯证券及交易委员会授权和监管（授权编码：250/14）。 加密货币CFD未向英国散户提供。",
	公司说明6:"Plus500AU Pty Ltd 持有以下牌照：即ASIC颁发的 AFSL（编号 417727）、纽西兰FMA 颁发的FSP（编号486026），以及南非FSCA 颁发的授权金融服务提供者（编号47546）。",
	公司说明7:"Plus500EE AS 受爱沙尼亚金融监管局授权及监管（牌照号码No. 4.1-1/18）。",
	公司说明8:"Plus500SG Pte Ltd (UEN 201422211Z) 持有新加坡金融管理局颁发的资本市场服务执照，允许进行资本市场产品交易（执照号码 CMS100648）。",
	公司说明9:"Plus500AE Ltd由迪拜金融服务管理局（F005651）授权和监管。",
	加入我们的社群:"加入我们的社群",
	PLUS500集团:"PLUS500集团",
	受监管的操作:"受监管的操作",
	健全的全球监管框架:"健全的全球监管框架",
	治理和社会责任:"治理和社会责任",
	底部说明:"底部说明",
	投资者联系方式:"投资者联系方式",
	投资者关系主管:"投资者关系主管",
	AI智慧型量化交易:"AI智慧型量化交易",
	订单执行快速可靠:"订单执行快速可靠",
	全天候自动分析行情:"全天候自动分析行情",
	交易受监管低风险:"交易受监管低风险",
	稳定策略高效收益:"稳定策略高效收益",
	取款快速且安全:"取款快速且安全",
	注册成功通知:"注册成功通知",
	游戏说明:"最低余额为10 USDT即可进入游戏并赚钱",
    
	
}
