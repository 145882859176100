export default {
  tabbar: {
    首页: 'Home',
    市场: 'Market',
    任务: '任务',
    团队: 'Team',
    我的: 'My'
  },
  navbar: {
    充值: 'Top up',
    提现: 'Withdraw',
    帮助: 'FAQ',
    分享: 'Referrer',
	推广: 'Promote',
    活动: 'Reward',
    游戏: 'Game',
  },	 
  payment: {
    付款方式详情: 'Payment method details',
    银行卡: '银行卡',
    钱包地址: 'Withdrawal method',
    交易密码: 'Transaction password',
    保存: 'Save'
  },
  market: {
    市场: 'Market',
    名称: 'Name',
    价格: 'Price',
  },
 
  task: {
    任务: '任务',
	我的余额: '我的余额',
    增加订单数量: '增加订单数量',
    今日剩余订单: '今日剩余订单',
    频率: '频率',
    手动交易: '手动交易',
    预期收益: '预期收益',
    需要启动存款: '需要启动存款',
    任务说明: '任务说明',
    每天可以完成10个任务: '每天可以完成10个任务',
    联系VIP助手增加任务数量: '联系VIP助手增加任务数量',
    每个用户的任务总数为300次: '每个用户的任务总数为300次',
    购买机器人无需人工操作即可自动交易: '购买机器人无需人工操作即可自动交易',
    我知道了: 'I see'
  },
  邀请好友注册领取佣金:"Invite friends to register and receive commissions",
  领取:"Receive",
  未满足条件:"Condition not met",
  总收入:"Total income",
  投资建议:"※Carry out a compound interest investment plan to get more substantial returns",
  语言切换: 'Language switch',
  POS区块节点挖矿: 'Ai Quantitative Trading Details',
  节点类型: 'Robot\nType',
  加入价格: 'Buy\nPrice',
  质押资金: 'POS\nfunds',
  质押周期: 'Period\n(days)',
  每日收入: 'Daily\nIncome',
  累计积分: 'Accumulated\nPoints',
  代理等级: 'Agent\nLevel',
  下级折扣比: 'Subordinate\nDiscount',
  代理折扣比: 'Agent\nDiscount',
  下属等级: 'Subordinate\nLevel',
  代理佣金比率: 'Agent\nCommission\nRate',
  下属每日收入: 'Subordinate\nDaily\nProfit',
  代理佣金: 'Agent\nCommission',
  引用对象: 'Reference\nobject',
  积分等级折扣规则: 'Points Tier Discount Rules',
  积分等级折扣规则介绍: 'Users who successfully participate in Ai quantitative trading will receive corresponding account points, and their agent superior users can also increase the corresponding points (applicable to 15% of direct subordinates). When the total account points meet the corresponding conditions, the level of the agent account will be improved. , Agent accounts at different levels can get different proportions of price discounts. Details of relevant point discounts are as follows:',
  积分等级折扣规则介绍2: 'Users with different relationships (direct) will receive discounts and agent-level discounts based on proportion to subordinates’ participation in Ai quantitative tradings. Other senior executives will also receive discount levels in proportion to their own agents. Price discounts for participating in Ai quantitative tradings: Users with corresponding agent levels will receive corresponding agent-level preferential rights when participating in Ai quantitative tradings.',
  团队: 'Team',
  代理佣金制度: 'Agency commission system',
  代理佣金说明详情: 'Proportion of LV1 agents obtained by A: 8%, ratio of LV2 agents obtained by A: 3%, ratio of LV3 agents obtained by A: 2%. Example: The daily profit of B, C, and D is: 100 USD, and the LV1 agent reward obtained by A: 8%*100=8 USD. The LV2 agent reward obtained by A: 3%*100=3 USD. The LV3 agent reward obtained by A: 2%*100=2 USD. The total commission income is: 8+3+2=13 USD.',
  代理结构图示: 'Proxy Structure Diagram',
  代理结构图示说明: 'A represents you, A invites B (A subordinate is B), B invites C (B subordinate is C), and C invites D (C subordinate is D). A can have multiple subordinates (B1, B2, B3, B4, B5, B6, etc.), B (B1, B2, B3) may also have multiple subordinates (C1, c2, c3, c4, c5, c6, etc.) C... D...',
  高级代理佣金说明: 'Senior Agent Commission Instructions',
  高级代理佣金说明详情: 'A represents you, A invites B (A subordinate is B), B invites C (Bs subordinate is C), and C invites D (C subordinate is D). The superior agent will obtain different agency commission income (%) according to the ratio of the total amount of node mining involved in its own account and subordinate accounts. A compares the daily income of subordinate members to obtain the corresponding ratio of agency rewards. Participating nodes must be up and running. Agency income is provided by INGSAI MINING for all financial expenses and will not affect the daily income of normal subordinate members.',
  高级代理佣金说明详情2: 'Example: The total mining amount of senior agents (A)>30% of the total mining amount of lower members (B/C/D) (Example: A>30% of B/C/D)',
  积分: 'Points',
  规则: 'Rule',
  会员级别: 'Membership Level',
  经验: 'Total points',
  代理信息: 'Membership info',
  更多: 'More',
  国码:"Country code",
  团队奖励: 'Team commission',
  昨天: 'Yesterday',
  本星期: 'This week',
  全部: 'All',
  机器人说明: "Users of Plus500Ai can purchase multiple quantitative trading packages at the same time, and the NO.1 robot can only purchase 10 times per account. Remember: Don’t forget to click to activate the strategy after purchasing the quantitative robot, otherwise there will be no income. The validity time of the quantitative robot is calculated based on the time of purchase. Please activate the quantitative trading strategy in time. If the quantitative robot has not been activated after it expires, you will lose the cost of purchasing the quantitative trading strategy package!!! All users participating in Plus500Ai quantitative trading please read carefully. This regulation! !",
  会员体验: 'Member experience',
  机器人采购: 'Robot Purchase',
  机器人启动押金: 'Node stake',
  机器人礼物: 'Node gift',
  机器人订单: 'Node order',
  一次性总和: 'Display',
  总计: 'Total',
  
  被激活: 'Already POS',
  工作中: 'ACTIVATED',
  暂停: 'PAUSE POS',
  结束: 'EXPIRED',
  刷新: 'refresh',
  来源: 'Source',
  价格: 'Robot Package Price',
  启动押金: 'POS amount',
  剩余时间: 'Time left',
  激活时间: 'Activate time',
  号: 'NO',
  我的资产: 'My assets',
  退出: 'Sign out',
  邀请码: 'Invitation code',
  我的钱包余额: 'My wallet balance',
  我的机器人仓库: 'My Robot List',
  收益面板: 'Earnings panel',
  去提款: 'Withdrawal',
  昨天代理返利: 'Agency commission yesterday',
  累计代理返利: 'Cumulative agency commission',
  今天机器人收益: "Today's trading profit",
  累计机器人收益: 'Cumulative trading income',
  累计机器人回扣: 'Accumulated trading commission',
  累计总回报: 'Cumulative total income',
  自动订单机器人数量: 'Activated quantitative trading robot',
  我的付款方式: 'My payment method',
  个人收入记录: 'Personal income',
  团队收入记录: 'Team income',
  记录详情:'Record details',
  充值记录: 'Recharge records',
  我的团队成员: 'My team members',
  活动中心: 'Rules Introduction',
  修改登录密码: 'Login password setting',
  交易密码管理: 'Transaction password setting',
  机器人: 'Ai Trading Strategy',
  机器人性能: 'Robot Panel',
  机器人价格: 'Trading Strategy Price',
  有效期: 'Trading Validity Period',
  钱包地址: 'Withdrawal method',
  天: 'Day',
  去购买: 'Buy',
  买个机器人: 'Buy Quant Trading Robot',
  我的钱包余额: 'My account balance',
  预计日收益: 'Estimated daily income',
  启动保证金: 'Startup deposit',
  请输入购买数量: 'Enter the purchase quantity',
  个机器人: 'Quant Trading Robot',
  机器人购买后需要激活才能工作: 'After purchasing a node, the corresponding amount of POS is required to start benefiting',
  机器人激活需要从余额中扣除相应的启动押金:
    'Node POS mining needs to deduct the corresponding pledge amount from the account balance',
  机器人暂停机器人停止时将返还启动押金不活跃的机器人可以赠送:
    'The amount of POS that can be returned immediately when the node POS mining is suspended',
  购买: 'BUY',
  点击去了解我们: 'Quantitative Trading',
  购买机器人: 'Buy robots',
  快速入口: 'Fast entry',
  '日/个人受益': 'Day/personal benefit',
  购买教程: 'AI Trading Robot List',
  激活金额: 'POS FEE',
  订单数量: 'Quantity of order',
  我们的合作伙伴: 'Our Crypto Market Partners',
  提款: 'Withdraw money',
  提款金额: 'Withdrawal amount',
  你还没有添加银行卡: 'You have not added a bank card',
  提现说明: 'Withdrawal instructions',
  说明1: '1.You can select with one click or manually enter the recharge amount',
  说明2: '2.It is recommended to use USDT (TRC20), the transfer fee is the lowest',
  说明3: '3.Customer service hours are Monday to Friday AM8 to PM6 Central American Time',
  说明4: 'Users who use USDT to recharge need to upload the correct screenshot of the successful transfer and TXID, and the account will be successfully received after the platform passes the review',
  说明5: '4.The minimum recharge is 8 USDT, which is convenient for the system to quickly review and process the account',
  说明6: '5.If you have any questions about recharging, please contact online customer service in time for assistance',
  确认提款: 'Confirm withdrawal ',
  请输入手机号码:'Phone number',
  请输入提现金额: 'Withdrawal amount ',
  提现费:'Withdrawal fee',
  提现须知: 'Please read the withdrawal rules carefully',
  TIPS1: '1:The daily withdrawal review time is from Monday to Friday AM8 to PM6. Withdrawals can be made on weekends, and the withdrawal review time is Monday',
  TIPS2: '2:7X24-hour system, withdraw money at any time, arrive at the account within 24 hours at the latest, and arrive at the account within 5 minutes at the shortest',
  TIPS3: '3:Online customer service time: Monday to Friday AM8 to PM6',
  TIPS4: '4:Due to the cost of USDT transfer, the platform charges a withdrawal fee when the user withdraws money',
  TIPS5: '5:The minimum withdrawal amount is 10 USDT',
  钱包余额: 'Wallet balance',
  输入数量:"Enter the quantity",
  数量:"Quantity",
  手机号码:"Phone number",
  手机号码已存在:"Mobile phone number has been registered",
  邮箱:"EMail",
  邮箱不能为空:"E-mail can not be empty",
  输入手机号码: 'Enter mobile number',
  请输入登录密码: 'Login password',
  登录: 'Log in',
  输入账号: 'Enter the account number',
  密码: 'Password',
  点击注册: "Don't have an account？Create one now!",
  忘记密码: 'Forget the password',
  重新登录: 'Re-login',
  密码找回成功: 'Password retrieved successfully',
  找回成功请重新登录: 'Retrieve successfully, please log in again',
  确认: 'Confirm',
  发送验证码: 'Send the verification code',
  再次输入密码: 'Confirm Password',
  输入验证码: 'Enter verification code',
  输入手机验证码:"Enter mobile OTP",
  输入手机号: 'Enter the phone number',
  输入密码: 'Login password',
  快速注册: 'Quick registration',
  注册: 'Register',
  立即登录: 'Already have an account, click to log in',
  请输入邮箱: 'Enter your Gmail email address',
  输入用户名: 'Enter your user name',
  请输入邀请码: 'Enter the invitation code',
  返回: 'Return',
  密码找回失败: 'Password recovery failed',
  输入的信息有误: 'The information entered is incorrect',
	获取验证码:"CAPTCHA",
	手机号码不能为空:"Phone number can not be blank",
	账号不存在:"Account does not exist",
	发送成功:"Sent successfully",
	今日收益:"(current income)",
	"您好如果您中途终止POS-STAKING系统将收取5%的STAKING费用":"Hello, if you terminate the POS midway, the system will charge a 5% POS fee. Special reminder: After the free node of the experience is suspended, you will not be able to obtain it again, nor will you be able to obtain any fees! !",
	我同意:"I agree",
	用户账号ID:"Account ID",
	取消:"Cancel",
	日收益:"Daily income",
	请输入购买数量:"Enter the purchase quantity",
	数量不能为空:"Quantity cannot be empty",
	加载中:"Loading...",
	唯一码:"Robot Coding",
	未激活:"INACTIVE",
	激活:"Start Strategy",
	购买时间:"Purchase time",
	"钱包余额不足，激活该机器人失败":"Insufficient account balance, node POS mining cannot be performed",

	运行时长:"Work time",
	签约购买:"balance purchase",
	系统赠送:"System gift",
	状态:"State",
	正常:"Normal",
	我的机器人:"My Quant Trading Robot",
	一级会员:"Level 1",
	二级会员:"Level 2",
	三级会员:"Level 3",
	人:"",
	充值客服:"Recharge customer service",
	充值:"Recharge",
	提现:"Withdraw",
	提款密码:"Withdrawal password",
	设置交易密码:"Set transaction password",
	登录密码:"Login password",
	请输入交易密码:"Enter transaction password",
	再次请输入交易密码:"Confirm transaction password",
	确认: 'Confirm',
	手机号码不能为空:"Phone number can not be blank",
	两次密码不一致:"The two passwords do not match",
	请输入验证码:"Enter email CAPTCHA",
	操作成功:"Successful operation",
	"用户名或密码不正确,登录失败":"Username or password is incorrect, login failed",
	登录成功:"Login successful",
	充值说明:"Recharge instructions",
	请先设置支付密码:"Please set the transaction password first",
	复制成功:"Copy successfully",
	冻结机器人做单本金:"Freeze quantitative funds",
	待审核:"Pending review...",
	成功:"Success",
	失败:"Failure",
	审核中:"Under review",
	在线充值:"Recharge online",
	描叙:"Describe",
	POS节点挖矿LV0:"POS-LV0 node mining",
	POS节点挖矿LV1:"POS-LV1 node mining",
	POS节点挖矿LV2:"POS-LV2 node mining",
	POS节点挖矿LV3:"POS-LV3 node mining",
	POS节点挖矿LV4:"POS-LV4 node mining",
	POS节点挖矿LV5:"POS-LV5 node mining",
	银行卡提现:"Bank Acc withdrawal",
	USDT提现:"USDT withdrawal",
	三级代理:"Third level agent",
	一级代理:"First level agent",
	二级代理:"Second level agent",
	一级:"LEVEL 1",
	二级:"LEVEL 2",
	三级:"LEVEL 3",
	做单:"Node mining",
	登录失败:"Login failed",
	请勿重复操作:"Do not repeat frequent operations",
	邀请码不正确:"Invitation code is incorrect",
	团队返佣:"Team commission",
	购买机器人返佣:"Robot Commission",
	本金返还:"POS return",
	佣金收入:"Commission income",
	时间:"Time",
	机器人做单返还:"POS amount refund",
	涨幅:"Change",
	市场:"Market",
	客服:"Service",
	验证码错误:"Verification code error",
  付款方式:"Withdrawal method",
	我的留言:"My message",
  暂无数据: 'No data',
  银行卡:'Bank account',
  提款方式:"Withdrawal method",
  修改: 'Modify',
 '请确保您的钱包地址正确，如果您的地址输入错误而导致收不到钱，请及时联系在线客服协助':'Please make sure your wallet address is correct. If you enter the wrong address and you cannot receive the money, please contact the online customer service in time for assistance',
 连接借记卡:"连接借记卡",
 银行名称:'Bank name',
 姓名姓氏: 'Your name',
 IBAN号码:'IBAN 号码',
 '* 重要提示:借记卡信息必须真实有效才能取款。':'* Important note: Bank account information must be true and valid to withdraw money。',
 帮助中心:'Help center',
 会员须知:'Member Notice',
 '24小时内使用USDT实时取款（推荐）':'1. 24小时内使用USDT实时取款（推荐）',
 '银行卡 取款 *取款时间为10:00-18:00 *发起取款后2小时内入账*申请非取款时间或跨行取款将于次日10:00-18:00到账':' 2.银行卡 取款 *取款时间为10:00-18:00 *发起取款后2小时内入账*申请非取款时间或跨行取款将于次日10:00-18:00到账',
 我知道了:'I see',
 链接点击:'Referral register link',
 确定:'Sure',
 建立自己的团队邀请:'Build your own team',
 此账号已冻结:"This account has been frozen",
 手机号存已存在:"Mobile phone number already exists",
 注册账号已存在:"Registered account already exists",
 请确定新密码:"Confirm the new password",
 请再次输入登录密码:"Enter the login password again",
 密码长度不能少于6位:"The password length cannot be less than 6 characters",
 加入:'join in',
 "你出售的机器人对方已接受,出售所得已打款到您账户,请查收。":
 	    "The node you sold has been accepted by the other party, and the proceeds from the sale have been transferred to your account, please check。",
 	"你出售的机器人对方已拒绝接受":"The node you sold has been rejected by the other party",
 	向你赠送一台机器人:"向你赠送一个节点，待确认",
 	向你出售一台机器人:"向你出售一个节点，待确认",
 	出售价格不能超过机器人价格:"出售价格不能超过节点价格",
 	银行卡已被绑定:"The bank card has been bound",
 	USDT_TRC20提现:'USDT_TRC20 withdrawal',
 	USDT_ERC20提现:'USDT_ERC20 withdrawal',
 	银行卡提现:'Bank card cash withdrawal',
 	邀请赠送:'Invitation bonus',
 	机器人返佣:'Robot Commission',
 	升级:' successful purchase ',
 	充值主网:'充值主网',
   "您被限制出售机器人,请联系客服了解详情":
     "You are restricted from selling nodes, please contact customer service for details",
   交易编号: "Transaction number",
   消息详情:"Message details",
   个人收益累计:"Accumulated personal income",
   今日代理收益:"Agency income today",
   代理累计收益:"Agency cumulative income",
   截图保存推荐给朋友:"Share your referral registration link and invite friends to join the Plus500Ai platform to earn team income commission rewards. The income commission for third-level team members is 8%-3%-2%. The team commission is based on the daily income data of lower-level members and will be automatically settled and entered into your account balance after 00:00 every day.",
   复制:"copy",
   充值提示1:"Tip 1: The minimum recharge is 5 USDT, you can recharge, otherwise you will not be credited",
   充值提示2:"Tip 2: Recharge needs to be confirmed on the chain. After successful recharge, it is expected to arrive in the wallet balance in about one minute。",
   请输入正确的充值金额:"Deposit amount ≥ 8 USDT",
   推荐:"recommend",
   充值金额:"Recharge amount",
   请上传付款截图:"Please upload a screenshot of the successful payment",
   交易号: 'Transaction Hash number',
   充值ID提示:"Please paste your Txid transaction serial number",
   "请确保钱包地址无误，如因钱包地址填写错误导致无法收款而造成的损失，将有用户自己承担。":"Please ensure that the wallet address is correct. If the wallet address is incorrectly filled in and the loss caused by the inability to receive money will be borne by the user。",
	上传凭证:"Upload proof of payment",
	充值确认: "Submit",
	不能为空:"Can not be empty",
  绑定钱包地址:'Bind wallet address',
	备注:"Remark",
	银行:"Bank",
	请输入银行卡号:"Please enter bank account number",
	请输入你的名字:"Please enter your name",
	银行卡号:"Bank account number",
	添加银行卡:"Add bank account",
	请选择银行:"Please select a bank",
	请输入钱包地址:"Please enter wallet address",
	钱包地址:"Wallet address",
	"密码错误次数过多,请等待x秒后再试":"Too many wrong passwords, please wait for 600 seconds and try again",
	"此账号已冻结,请联系客服":"This account has been frozen, please contact customer service",
	手机号格式错误:"Malformed phone number",
	"取款须知":"Withdrawal Notice",
	"须知1":"1. Use USDT-TRC20 for real-time withdrawal within 24 hours (recommended)",
	"须知2":"2. Withdrawal on Saturday and Sunday",
	"须知3":"*Users can withdraw money on Saturday and Sunday",
	"须知4":"*Weekend withdrawals will arrive on Monday 10AM-8PM",
	我知道了:"I see",
	"提现0":"1USDT=1USD ERC20",
	"提现1":"提示1：最低单笔提现100 USDT/天，单笔交易手续费5 USDT/次",
	"提现2":"TRC20提示2：每次提款最低10 USDT，每次交易手续费1 USDT。",
	"提现3":"银行账户提示3：最低单次取款为$10，交易手续费为交易金额的$6%",
	"提现4":"提现时间：提现申请提交后24小时内到账",
	
	已绑定:"Bound",
	去设置:"Go to settings",
	注册成功:"Registration success",
	汇率:"Exchange rate",
	请输入正确的提现金额:"Please enter the correct withdrawal amount",
	提款记录:"Withdrawal history",
	"绑定的钱包地址必须跟付款钱包一致，否则不会到账。":"tip1:绑定的钱包地址必须跟付款钱包一致，否则不会到账。",
	"充值成功后预计在一分钟左右到账，请检查钱包约。":"tip2:充值成功后预计在一分钟左右到账，请检查钱包余额。",
	请先绑定钱包地址:"Please bind the wallet address first",
	输入邮箱:"Enter email",
	邮箱已存在:"EMAIL already exists",
	注册邮箱已存在:"Registered email already exists",
	指定用户不存在:"The specified user does not exist",
	今日涨幅:"Change",
	恭喜新用户:"Congratulations new user ",
	快捷充币:"Deposit",
	快速买USDT:"USDT recharge",
	在线支付:"Online Payment",
	自动到账:"Automatic account",
	线下支付:"Offline payments",
	联系客服获取验证码:"Contact customer service to get the verification code",
	获取:"Obtain",
	退出成功:"Exit successfully",
	不能低于最小充值:"Cannot be lower than the minimum recharge",
	不能低于最小提现金额:"Cannot be lower than the minimum withdrawal amount",
	最小提现:"Minimum withdrawal",
	设置成功:"Successfully set",
	官方充币:"Official deposit",
	银行卡充值:"Bank card recharge",
	等待审核:"Waiting for review",
	可提现金额不足:"Insufficient amount available for withdrawal",
	未经授权:"Unauthorized",
	交易密码不正确:"The transaction password is incorrect",
	提现次数不足:"Insufficient withdrawal times",
	官网:"official website",
	账户usdt不足:"Account USDT balance is insufficient",
	激活成功: "POS success",
	操作成功: "Successful operation",
	修改成功:"Successfully modified",
	注册说明:"Mobile OTP and Email CAPTCHA only need to choose 1 method to verify and you can register successfully",
	弹窗内容:
	"Welcome to the Plus500Ai quantitative trading platform. Each user can use the registration bonus of 1 USDT to purchase the NO.0 quantitative robot trading strategy package, which is valid for 1 day and earn 0.5 USDT for free. Other quantitative trading strategy packages require corresponding fees to be used. After users purchase the robot, they need to activate the quantitative trading strategy before it can automatically start running. Each trading strategy corresponds to a different validity period. If the user does not click to activate the strategy after purchasing the corresponding robot points, you will not receive any trading income.",
	标题1:'1.Plus500 platform introduction',
	标题2:'2. Advantages of AI quantitative trading',
	标题3:'3. Why choose Plus500Ai?',
	标题4:'4. How to join Plus500Ai',
	标题5:'5.Plus500Ai market prospects',
	标题6:'6.Plus500Ai revenue model',
	标题7:'7.Deposit and Withdrawal Regulations',
	标题8:"Battle Royale Gameplay",
	内容8:"There are 8 rooms in the battle royale game on the Plu500Ai platform. Players can choose any room among the 8 rooms to put gold coins in. After the system countdown ends, a killer will appear in the game system. The killer randomly enters a room and eliminates the people in the room. Other players can share the gold coins in this room and gain profits. The gameplay of the battle royale game is relatively short, with a game lasting only a few minutes, so players can play it in their free time. The amount of division = the total number of rooms killed by the killer in this period. Actual share amount = share amount * 0.9 (excluding the destruction ratio of 0.1). Share ratio = actual share amount / total bet amount in other rooms. Winning amount = sharing ratio * actual betting amount. 1 gold coin = 1 USDT, and a minimum of 10 USDT is required to participate in the battle royale game.",
	内容1:"Plus500 trading platform is provided by Plus500SEY Ltd. Accordingly, Plus500SEY Ltd is the issuer and seller of the financial products described or offered on this website. Plus500SEY Ltd is a Seychelles-based company (Company No. 8426415-1) with its offices in Victoria. The company is authorized and supervised by the Seychelles Financial Services Authority (FSA) with license number SD039 and provides Contracts for Difference (CFD) on various basic products. The company is a rapidly growing CFD provider that currently offers a portfolio of over 2,800 financial instruments. Plus500SEY Ltd is a subsidiary of Plus500 Ltd, which is listed on the Main Market of the London Stock Exchange and is headquartered in Haifa.",
	内容2:'The advantage of AI fully automated quantitative trading is to use rational tools to operate transactions according to model settings. The robot has no emotional changes and can overcome human nature, human greed and fear and many other shortcomings and weaknesses in the operation process. Manual operations are often affected by the rise and fall of the market and various other factors, and cannot operate according to the best profit model for a long time.',
	内容3:'Plus500Ai has gradually strengthened its position as a multi-asset fintech group by expanding its core products into new and existing markets, launching new trading and financial products, and deepening its engagement with customers. Protect your assets and protect your encryption keys with industry-leading security technology. Plus500Ai also offers global custody, which means we are regulated and audited and insured up to $250 million. Deploy your capital with the goal of not just holding assets, but putting them to work. Plus500Ai allows you to deploy your funds in a variety of ways, from Ai quantitative trading to classic primary services, depending on your risk tolerance. Online platform to provide more services to your own users without hiring additional blockchain engineers.',
	内容4:"Since the establishment of Plus500Ai, there has only been one official website, WWW.PLUS500AI.COM. All users need to log in to the official website to download the APP, as other websites are not our company's. To register, you need to submit the user's mobile phone number, email address and recommender's invitation code to register quickly. All communications with customers are in writing, either via email or online chat. To contact Plus500 via email, please complete the form on the Contact Us page (Request Form). After submitting the application form, Plus500 will send a reply via email directly to the email address you specified in the application form. If you have any questions, please contact customer service.",
	内容5:'Plus500Ai operates across products, devices and countries to provide customers with reliable and intuitive financial trading opportunities. We have always pursued excellence in our products and teams. With advanced proprietary technology and a customer-centric approach, our users receive a first-class trading service experience.',
	内容6:"Plus500Ai provides professional quantitative trading strategies for the cryptocurrency market by a team of senior data analysts. Users who invest in the Ai quantitative trading robot can obtain quantitative trading profits by relying on the trading strategies matched with the robot. The Ai quantitative trading robot cyclically analyzes the optimal trading time based on Plus500Ai's market trading strategy, and obtains quantitative trading profits through the low-level buying and selling at high prices model. Plus500Ai provides investing users with optimal quantitative trading strategies every day, and the platform extracts 5% of users’ quantitative income profits as platform operating funds.",
	内容7:'Plus500Ai currently supports USDT deposits and withdrawals, and there will be an announcement on the official website if other channels are opened in the future. The minimum deposit and withdrawal are both 10 USDT. The fastest deposit and withdrawal are within 5 minutes and the slowest within 24 hours. There is no limit on user withdrawal time, you can withdraw money at any time. Withdrawals arrive within 5 minutes - 24 hours on normal business days. Weekend withdrawals are arranged to be processed on Monday.',
	BSC提现:"USDT_BSC withdrawal",
	社区Ads收益:"Community Ads revenue",
	Ads收益区间:"Ads income 1-1000$",
	申请Ads费用:"Application Ads Fee",
	Ads促销地址:"Ads promotion address",
	填写Ads促销地址:"Fill in Ads promotion URL",
	申请Ads费用须知:"Notes on applying for Ads fee",
	须知A:"1. Users record promotional videos and upload them to their own YouTube, Facebook, channels or other communities for Ads promotion, apply for a $1-$1000 bonus, and the platform will review your Ads video material 3 days after submitting the application, and put the Ads bonus into the account according to the video promotion effect Your account balance, videos without any promotional effects cannot be reviewed",
	须知B:"2. The same Ads promotional video on the same channel can only apply for a bonus once, and new promotional video materials can be released once a week. Do not send malicious repeated requests. If you maliciously submit Ads requests not made by yourself on the platform multiple times or Ads materials that fail to be reviewed, your account will be banned after the system detects",
	须知C:"3. The Ads video material recorded by the user needs to have a basic platform introduction and platform function explanation. The video time is not shorter than 5 minutes. The more subscribers to your channel, the better the promotional effect of the video, and the higher the Ads fee you can apply for. The specific Ads fees are as follows, taking YOUTUBE as an example:",
	须知D: "Subscriber 0-2K: application amount 1-20$",
	须知D1:"Subscribers 3K-10K: application amount 21-40$",
	须知D2:"Subscriber 11K-30K: application amount 41-100$",
	须知D3:"Subscriber 31K-50K: application amount 101-300$",
	须知D4:"Subscriber 51K-100K: application amount 301-1000$",
	须知D5:"4. The system will review and process Ads bonus applications every Sunday, and qualified users will receive bonuses sent by the system.",
	请求审核:"Request for review",
	申请金额:"Application Amount",
	URL促销地址:"URL Promotion Address",
	提交时间:"Submit time",
	需要更新的银行卡不存在:"The bank card to be updated does not exist",
	预计收入:"Estimated income",
	总投资:"Total investment",
	购买价:"Purchase Price",
	邀请:"Invite",
	积分等级折扣规则介绍3:"This discount amount is only valid until 31 December 2023, after 1 January 2024 there will be a new adjustment.",
	成员:"members",
	LV1佣金:"LEVEL1 Commission",
	LV2佣金:"LEVEL2 Commission",
	LV3佣金:"LEVEL3 Commission",
	合格条件:"Deposit minimum 8$ per person",
	随时可退:"Refund anytime",
	登录密码错误:"Wrong login password",
	充值奖金:"Deposit USDT ≥ 50USDT, bonus 3%",
	充值奖金1:"Deposit IDR ≥ 50USDT, bonus 2%",
	充值奖金2:"Deposit THB ≥ 50USDT, bonus 5% ",
	活动已过期:"The event has expired, waiting for the next event",
	不能重复发送短信:"You cannot send repeated text messages within 10 minutes. It is recommended that you use EMAIL CAPTCHA to register directly.",
	限购说明1:"Hello, each user of the NO.0 quantitative robot trading strategy package can only purchase it once.",
	修改银行提示:"The withdrawal bank account cannot be modified after it is bound. If you need assistance, please contact customer service.",
	修改钱包地址提示:"If you need to modify the associated USDT wallet address, please contact customer service for verification.",
	注册推送消息1:"Welcome to INGSAI. After new users start a POS mining node, they will receive POS mining income every 5 minutes. The team commission income will be automatically entered into the account balance at about 00.00 per day.",
	限购说明:"Hello, the maximum number of purchases for this type of quantitative robot package is 10 times. It is recommended that you purchase other types of quantitative trading packages.",
	注册协议:"By pressing (Create Account), you confirm that you are over 18 years old. You are aware that Plus500AI may use your email address or other personal data to contact you electronically to provide relevant information about its products/services. We may also notify you of market events to help you get the most out of your trading experience. You can manage and modify notification preferences in the (Notification Settings) and (Privacy Settings) tabs on the Platform. You can unsubscribe from these communications at any time. For more information, please review our (Privacy Agreement)",
	公司说明1:"Plus500AI is a registered trademark of Plus500 Ltd. Plus500 Ltd operates through the following subsidiaries:",
	公司说明2:"Plus500SEY Ltd is authorized and regulated by the Seychelles Financial Services Authority (License No. SD039).",
	公司说明3:"Plus500SEY Ltd is the issuer and seller of the financial products described or available on this website.",
	公司说明4:"Plus500UK Ltd is authorized and regulated by the Financial Conduct Authority. Financial Conduct Authority Registration Number: 509909. Cryptocurrency CFDs are not available to retail users.",
	公司说明5:"Plus500CY Ltd is authorized and regulated by the Cyprus Securities and Exchange Commission (authorization code: 250/14). Cryptocurrency CFDs are not available to UK retail investors.",
	公司说明6:"Plus500AU Pty Ltd holds the following licenses: AFSL (No. 417727) issued by ASIC, FSP (No. 486026) issued by the New Zealand FMA, and Authorized Financial Services Provider (No. 47546) issued by the South Africa FSCA.",
	公司说明7:"Plus500EE AS ​​is authorized and regulated by the Estonian Financial Supervisory Authority (License No. 4.1-1/18).",
	公司说明8:"Plus500SG Pte Ltd (UEN 201422211Z) holds a capital markets services license issued by the Monetary Authority of Singapore, allowing it to trade capital market products (license number CMS100648).",
	公司说明9:"Plus500AE Ltd is authorized and regulated by the Dubai Financial Services Authority (F005651).",
	加入我们的社群:"Join Our Community",
	PLUS500集团:"PLUS500 GROUP’S",
	受监管的操作:"REGULATED OPERATIONS",
	健全的全球监管框架:"Robust global regulatory framework",
	治理和社会责任:"GOVERNANCE AND SOCIAL RESPONSIBILITY",
	底部说明:"Plus500 remains dedicated to operating responsibly and sustainably in all aspects of its business and believes this approach is both its duty and an essential part of effective management. Plus500 is committed to a range of ESG initiatives to create tangible value for our people, customers, local communities and charities and our shareholders.",
    投资者联系方式:"INVESTOR CONTACTS",
	投资者关系主管:"Head of Investor Relations",
	AI智慧型量化交易:"AI Smart Quantitative Trading",
	订单执行快速可靠:"Order execution is fast and reliable",
	全天候自动分析行情:"Automatic market analysis around the clock",
	交易受监管低风险:"Trading is regulated and low risk",
	稳定策略高效收益:"Stable strategy and efficient returns",
	取款快速且安全:"Withdraw money quickly and securely",
	游戏说明:"The minimum balance required is 10 USDT to enter the game and make money",
	
	
}
