<template>
  <div class="page">
    <div class="header">
      <div @click="getBack">
        <img class="img" src="../../assets/img/arrow.png" alt="" />
      </div>
      <div class="logg" style="font-weight: bold; color:#fff;">{{ $t('我的团队成员') }}</div>
      <div></div>
    </div>
    <div class="choose">
      <van-tabs v-model="active">
        <van-tab :title="$t('Level1')" name="1">
         
          <van-list
            v-model="loading"
            :finished="finished"
            :finished-text="$t('暂无数据')"
            @load="onLoad"
          >
            <van-cell v-for="item in list" :key="item.id">
              <div class="learn">
                <div class="box">
                  <div>
                    <div class="natixis">
						<img class="imgd" style="width:24px; height:24px;vertical-align:middle;" src="../../assets/img/phone.png" alt="" /> {{$t('手机号码')}}:{{item.mobile}}</div> 
                    <div><img class="imgd" style="width:18px; height:18px;vertical-align:middle;" src="../../assets/img/email.png" alt="" /> {{$t('邮箱')}}:{{item.username}}</div>
					<div><img class="imgd" style="width:20px; height:20px;vertical-align:middle;" src="../../assets/img/time.png" alt="" /> {{$t('时间')}}:{{item.addtime}}</div>
                  </div>
                </div>
              </div>
            </van-cell>
          </van-list>
        </van-tab>
        <van-tab :title="$t('Level2')" name="2">
		<van-list
		  v-model="loading"
		  :finished="finished"
		  :finished-text="$t('暂无数据')"
		  @load="onLoad"
		>
		  <van-cell v-for="item in list" :key="item.id">
		    <div class="learn">
		      <div class="box">
		        <div>
		          <div class="natixis">
		          						<img class="imgd" style="width:24px; height:24px;vertical-align:middle;" src="../../assets/img/phone.png" alt="" /> {{$t('手机号码')}}:{{item.mobile}}</div> 
		            <div><img class="imgd" style="width:18px; height:18px;vertical-align:middle;" src="../../assets/img/email.png" alt="" /> {{$t('邮箱')}}:{{item.username}}</div>
		          					<div><img class="imgd" style="width:20px; height:20px;vertical-align:middle;" src="../../assets/img/time.png" alt="" /> {{$t('时间')}}:{{item.addtime}}</div>
		          </div>
		      </div>
		    </div>
		  </van-cell>
		</van-list>
         
        </van-tab>
		<van-tab :title="$t('Level3')" name="3">
		     <van-list
		       v-model="loading"
		       :finished="finished"
		       :finished-text="$t('暂无数据')"
		       @load="onLoad"
		     >
		       <van-cell v-for="item in list" :key="item.id">
		         <div class="learn">
		           <div class="box">
		             <div>
		               <div class="natixis">
		               						<img class="imgd" style="width:24px; height:24px;vertical-align:middle;" src="../../assets/img/phone.png" alt="" /> {{$t('手机号码')}}:{{item.mobile}}</div> 
		                 <div><img class="imgd" style="width:18px; height:18px;vertical-align:middle;" src="../../assets/img/email.png" alt="" /> {{$t('邮箱')}}:{{item.username}}</div>
		               					<div><img class="imgd" style="width:20px; height:20px;vertical-align:middle;" src="../../assets/img/time.png" alt="" /> {{$t('时间')}}:{{item.addtime}}</div>
		               </div>
		           </div>
		         </div>
		       </van-cell>
		     </van-list>
		  <!-- <div class="pitera" v-if="list == ''">
		    <img src="../../assets/img/机器人.png" alt="" />
		    <div>{{$t('暂无数据')}}</div>
		  </div> -->
		</van-tab>
      </van-tabs>
    </div>
  <!--  <div class="footer">
      <span class="comeTo">{{ $t('总计') }} :</span>
      <span class="num"> 30</span>
      <span class="comeTo"> {{ $t('一次性总和') }} :</span>
      <span class="addUpTo">30</span>
    </div> -->
    <ROBOT></ROBOT>
  </div>
</template>

<script>
import ROBOT from '@/components/robot'
import { NavBar, Tab, Tabs, Empty, PullRefresh, Toast, List } from "vant";

import { apigetagent,apiuserlist } from "@/http/api/";
export default {
  name: 'welcome',
  components: {
    ROBOT
  },
  data() {
    return {
      active: '1',
      list: [],
      loading: false,
      finished: false,
	  page:1,
	  token:'',
	  
    }
  },
  watch: {
    active() {
			this.page = 1;
      this.list = []
      this.loading = false
      this.finished = false
      this.onLoad()
      console.log('gaibain')
	  
    }
  },
  created() {
    	var token = localStorage.getItem("key");
    	console.log("token", token);
    	if (!token) {
    	  this.$router.replace("/login");
    	}
    	this.token = token;
    },
  methods: {
    copyEvent() {
      console.log('fuzhi ')
    },
    getBack() {
      this.$router.back()
    },
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
		Toast.loading({
		  duration: 0,
		  message: this.$t('加载中'),
		  forbidClick: true,
		});
        var that = this;
		  apiuserlist({
		  userid: this.token,
		  page:this.page,
		  type:this.active
		  }).then((res) => {
		  Toast.clear();
		  for(let i = 0; i < res.info.length; i++){
			  that.list.push(res.info[i]);
		  }
		  // 数据全部加载完成
		  if(res.info.length ==0){
		  		that.finished = true
		  }
		});
		this.page++
		// 加载状态结束
		this.loading = false
        
      
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  //   padding: 0 30px;
  background:#07183d;
  .header {
    padding: 0 40px;
    height: 56px;
    line-height: 56px;
    display: flex;
    justify-content: space-between;
    .close {
      width: 28px;
      height: 28px;
    }
	
    .img {
      width: 40px;
	  margin-top:15px;
    }
    .logg {
      font-size: 40px;
      font-family: PingFang SC-中粗体, PingFang SC;
      font-weight: normal;
      color: #ffffff;
    }
  }
  .choose {
    margin-top: 60px;
    padding: 0 24px;
    .learn {
      margin-top: 30px;
      color: #fff;
      padding: 20px 25px;
      border-bottom: 1px solid #0D6EFD;
      .box {
        color: #000;
        // margin-bottom: 10px;
        align-items: center;
        display: flex;
        justify-content: space-between;
        .natixis {
          margin-bottom: 20px;
        }
      }
    }
  }
  .pitera {
    color: #fff;
    line-height: 60px;
    font-size: 24px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .footer {
    position: fixed;
    font-size: 24px;
    color: #888;
    bottom: 0;
    height: 90px;
    width: 100%;
    text-align: center;
    line-height: 90px;
    background-color: #2a2c2e;
    border-radius: 30px 30px 0 0;
    .num {
      color: #0d9135;
      font-size: 30px;
      margin-right: 30px;
    }
    .comeTo {
      margin-right: 20px;
    }
    .addUpTo {
      font-size: 30px;
      color: #fff;
    }
  }
}
</style>
