export default {
  tabbar: {
    首页: '1면',
    市场: '시장',
    任务: '任务',
    团队: '팀',
    我的: '나의'
  },
  navbar: {
    充值: '재충전',
    提现: '철회하다',
    帮助: 'FAQ',
    分享: '초대',
	推广: '광고 수익',
    活动: '보너스',
    游戏: '게임',
  },	 
  payment: {
    付款方式详情: '결제 수단 세부정보',
    银行卡: '银行卡',
    钱包地址: '지갑 주소',
    交易密码: '거래 비밀번호',
    保存: '제출'
  },
  market: {
    市场: '시장',
    名称: '이름',
    价格: '가격',
  },
 
  task: {
    任务: '任务',
	我的余额: '我的余额',
    增加订单数量: '增加订单数量',
    今日剩余订单: '今日剩余订单',
    频率: '频率',
    手动交易: '手动交易',
    预期收益: '预期收益',
    需要启动存款: '需要启动存款',
    任务说明: '任务说明',
    每天可以完成10个任务: '每天可以完成10个任务',
    联系VIP助手增加任务数量: '联系VIP助手增加任务数量',
    每个用户的任务总数为300次: '每个用户的任务总数为300次',
    购买机器人无需人工操作即可自动交易: '购买机器人无需人工操作即可自动交易',
    我知道了: '我知道了'
  },
  邀请好友注册领取佣金:"친구를 초대하여 등록하고 커미션을 받으세요",
  领取:"받다",
  未满足条件:"조건이 충족되지 않음",
  总收入:"총 수입",
  投资建议:"※더 많은 수익을 얻으려면 복리 투자 계획을 실행하세요.",
  语言切换: '언어 전환',
  POS区块节点挖矿: 'Ai 퀀트 트레이딩 세부정보',
  节点类型: '로봇\n유형',
  加入价格: '구매\n가격',
  质押资金: 'POS\n자금',
  质押周期: '주기\n(일)',
  每日收入: '일일\n수입',
  累计积分: '누적\n포인트',
  代理等级: '요원\n레벨',
  下级折扣比: '하위\n할인',
  代理折扣比: '에이전트\n할인',
  下属等级: '하위\n수준',
  代理佣金比率: '에이전트\n수수료\n요율',
  下属每日收入: '하위\n매일\n수익',
  代理佣金: '에이전트\n커미션',
  引用对象: '참조\n개체',
  积分等级折扣规则: '포인트 등급 할인 규정',
  积分等级折扣规则介绍: 'Ai 퀀트 트레이딩에 성공한 사용자는 해당 계정 포인트를 받게 되며, 에이전트 상위 사용자도 해당 포인트를 늘릴 수 있습니다.(직속 하위 사용자의 15%에 적용) 총 계정 포인트가 해당 조건을 충족할 경우 에이전트의 레벨이 결정됩니다. 계정이 개선될 것입니다. , 다양한 수준의 에이전트 계정은 서로 다른 비율의 가격 할인을 받을 수 있습니다. 해당 포인트 할인 내역은 다음과 같습니다.',
  积分等级折扣规则介绍2: '서로 다른 관계(직접)를 가진 사용자는 하위 사용자의 Ai Quantum 거래 참여 비율에 따라 할인 및 에이전트 수준 할인을 받습니다. 다른 고위 임원들도 자신의 대리인에 비례하여 할인 수준을 받게 됩니다. Ai 수량 거래 참여 시 가격 할인: 해당 에이전트 수준의 사용자는 Ai 수량 거래에 참여할 때 해당 에이전트 수준의 우선권을 받게 됩니다',
  团队: '팀',
  代理佣金制度: '대행사 수수료 제도',
  代理佣金说明详情: 'A가 얻는 LV1제의 비율: 8%, A가 얻는 LV2제의 비율: 3%, A가 얻는 LV3제의 비율: 2%. 예: B, C, D의 일일 수익은 100 USD이고 A가 얻은 LV1 에이전트 보상은 8%*100=8 USD입니다. A가 획득한 LV2 에이전트 보상: 3%*100=3 USD. A가 획득한 LV3 에이전트 보상: 2%*100=2 USD. 총 수수료 수입은 8+3+2=13 USD입니다.',
  代理结构图示: '프록시 구조 다이어그램',
  代理结构图示说明: 'A는 당신을 대표하고, A는 B(A의 부하 B)를 초대하고, B는 C(B의 부하 C)를 초대하고, C는 D(C의 부하 D)를 초대합니다. A는 여러 하위(B1, B2, B3, B4, B5, B6 등)를 가질 수 있고, B(B1, B2, B3)는 또한 여러 하위(C1, c2, c3, c4, c5, c6 등)를 가질 수 있습니다. ) CD...',
  高级代理佣金说明: '고위 요원 위원회 지침',
  高级代理佣金说明详情: 'A는 당신을 대표하고, A는 B(A의 부하 B)를 초대하고, B는 C(B의 부하 C)를 초대하고, C는 D(C의 부하 D)를 초대합니다. 상위 에이전트는 자신의 계정과 하위 계정에 포함된 노드 마이닝 총액의 비율에 따라 다른 에이전트 수수료 수입(%)을 얻습니다. A는 하위 멤버들의 일당 수입을 비교하여 그에 상응하는 소속사 보상 비율을 구한다. 참여 노드가 실행 중이어야 합니다. 대행수입은 모든 금융비용을 포스마이닝에서 제공하며, 일반 서브멤버의 일수입에는 영향을 미치지 않습니다.',
  高级代理佣金说明详情2: '예: 상위 에이전트(A)의 총 채굴량 > 하위 멤버(B/C/D)의 총 채굴량의 30% (예: A > B/C/D의 30%)',
  积分: '전철기',
  规则: '규칙',
  会员级别: '회원등급',
  经验: '총 포인트',
  代理信息: '구성원 정보',
  更多: '더',
  团队奖励: '팀 커미션',
  昨天: '어제',
  本星期: '이번 주',
  全部: '모든 사람들',
  机器人说明: 'Plus500Ai 사용자는 동시에 여러 정량 거래 패키지를 구매할 수 있으며, NO.1 로봇은 계정당 10회만 구매할 수 있습니다. 기억하세요: 정량 로봇을 구매한 후 전략을 활성화하려면 클릭하는 것을 잊지 마세요. 그렇지 않으면 수입이 없습니다. 퀀트 로봇의 유효기간은 구매 시점을 기준으로 계산됩니다. 퀀트 트레이딩 전략을 제때에 활성화해 주세요. 만료 후에도 퀀트 로봇이 활성화되지 않으면 퀀트 트레이딩 전략 패키지 구매 비용을 잃게 됩니다! !!Plus500Ai 퀀트 트레이딩에 참여하는 모든 사용자는 반드시 읽어보시기 바랍니다. !',
  会员体验: '회원 경험',
  机器人采购: '로봇구매',
  机器人启动押金: '노드 POS 금액',
  机器人礼物: '노드 선물',
  机器人订单: '노드 순서',
  一次性总和: '보이다',
  总计: '계',
  
  被激活: '이미 POS',
  工作中: '활성화됨',
  暂停: 'POS 일시 중단',
  结束: '만기가 되다',
  刷新: '새로 고침',
  来源: '원천',
  价格: '로봇패키지가격',
  启动押金: 'POS 금액',
  剩余时间: '남은 시간',
  激活时间: '활성화 시간',
  号: '숫자',
  我的资产: '내 자산',
  退出: '로그아웃',
  邀请码: '초대 코드',
  我的钱包余额: '내 지갑 잔액',
  我的机器人仓库: '나의 로봇 리스트',
  收益面板: '수익 패널',
  去提款: '돈을 인출',
  昨天代理返利: '어제 대행사 수수료',
  累计代理返利: '누적 대행사 수수료',
  今天机器人收益: '오늘의 거래 이익',
  累计机器人收益: '누적 거래 수익',
  累计机器人回扣: '누적된 거래 수수료',
  累计总回报: '누적 총 수입',
  自动订单机器人数量: '활성화된 양적 거래 로봇',
  我的付款方式: '내 결제 수단',
  个人收入记录: '개인 소득',
  团队收入记录: '팀 수입',
  记录详情:'기록 세부정보',
  充值记录: '기록 충전',
  我的团队成员: '내 팀 구성원',
  活动中心: '규칙 소개',
  修改登录密码: '로그인 비밀번호 설정',
  交易密码管理: '거래 비밀번호 설정',
  机器人: 'AI 트레이딩 전략',
  机器人性能: '로봇 패널',
  机器人价格: '거래 전략 가격',
  有效期: '무역 유효기간',
  钱包地址: '지갑 주소',
  天: '일',
  去购买: '구입',
  买个机器人: '퀀트 트레이딩 로봇 구매',
  我的钱包余额: '내 계정 잔액',
  预计日收益: '예상 일일 수입',
  启动保证金: '창업예금',
  请输入购买数量: '구매수량을 입력해주세요',
  个机器人: '퀀트 트레이딩 로봇',
  机器人购买后需要激活才能工作: '노드 구매 후 혜택을 시작하려면 해당 POS 금액이 필요합니다.',
  机器人激活需要从余额中扣除相应的启动押金:
    '노드 POS 마이닝은 계정 잔액에서 해당 POS 금액을 차감해야 합니다.',
  机器人暂停机器人停止时将返还启动押金不活跃的机器人可以赠送:
    '노드 POS 채굴 중단 시 즉시 반환 가능한 POS 수량',
  购买: '구입',
  点击去了解我们: '양적 거래',
  购买机器人: '로봇 구입',
  快速入口: '빠른 입장',
  '日/个人受益': '일/개인 혜택',
  购买教程: 'AI 트레이딩 로봇 목록',
  激活金额: 'POS 수수료',
  订单数量: '주문량',
  我们的合作伙伴: '우리의 암호화 시장 파트너',
  提款: '돈을 인출',
  提款金额: '인출 금액',
  你还没有添加银行卡: '你还没有添加银行卡',
  提现说明: '출금 안내',
  说明1: '1.한 번의 클릭으로 선택하거나 충전 금액을 수동으로 입력할 수 있습니다.',
  说明2: '2.USDT(TRC20) 사용 권장, 송금 수수료가 가장 낮음',
  说明3: '3.고객 서비스 시간은 월요일~금요일 AM8~PM6 중미 표준시입니다.',
  说明4: 'USDT를 사용하여 충전하는 사용자는 성공적인 전송 및 TXID의 올바른 스크린샷을 업로드해야 하며 플랫폼이 검토를 통과한 후 계정이 성공적으로 수신됩니다.',
  说明5: '4.최소 재충전은 8 USDT이며 시스템에서 계정을 신속하게 검토하고 처리하는 데 편리합니다.',
  说明6: '5.충전에 대한 질문이 있는 경우 온라인 고객 서비스에 적시에 연락하여 도움을 받으십시오.',
  确认提款: '출금 확인 ',
  请输入手机号码:'전화 번호',
  请输入提现金额: '출금 금액 ',
  提现费:'인출 수수료',
  提现须知: '출금 규정을 주의 깊게 읽으십시오',
  TIPS1: '1:일일 출금 검토 시간은 월요일부터 금요일까지 AM8부터 PM6까지입니다. 출금은 주말에도 가능하며, 출금심사시간은 월요일',
  TIPS2: '2:7X24시간제, 수시출금, 늦어도 24시간 이내 계좌도착, 최단 5분 이내 계좌도착',
  TIPS3: '3:온라인 고객 서비스 시간: 월요일~금요일 AM8~PM6',
  TIPS4: '4:USDT 전송 비용으로 인해 사용자가 돈을 인출할 때 플랫폼에서 인출 수수료를 부과합니다.',
  TIPS5: '5:최소 인출 금액은 10 USDT입니다.',
  钱包余额: '지갑 잔액',
  输入数量:"수량 입력",
  数量:"수량",
  手机号码:"전화 번호",
  手机号码已存在:"휴대폰번호가 등록되었습니다",
  邮箱:"이메일 주소",
  输入手机号码: '휴대폰 번호 입력',
  请输入登录密码: '로그인 비밀번호',
  登录: '로그인',
  输入账号: '계좌번호를 입력하세요',
  密码: '비밀번호',
  点击注册: '아직 계정이 없나요? 지금 계정을 만드세요!',
  忘记密码: '암호를 잊어',
  重新登录: '다시 로그인',
  密码找回成功: '비밀번호가 성공적으로 검색되었습니다.',
  找回成功请重新登录: '비밀번호가 성공적으로 검색되었습니다. 다시 로그인하십시오',
  确认: '확인하다',
  发送验证码: '인증 코드 보내기',
  再次输入密码: '패스워드 확인',
  输入验证码: '보안 문자를 입력하세요.',
  输入手机验证码:"모바일 OTP를 입력하세요",
  输入手机号: '휴대폰 번호 입력',
  输入密码: '로그인 비밀번호',
  快速注册: '빠른 등록',
  注册: '레지스터',
  立即登录: '이미 계정이 있습니다. 클릭하여 로그인',
  请输入邮箱: 'Gmail 주소',
  输入用户名: '사용자 이름을 입력하세요',
  请输入邀请码: '초대코드를 입력해주세요',
  返回: '반품',
  密码找回失败: '비밀번호 복구 실패',
  输入的信息有误: '입력한 정보가 잘못되었습니다',
	获取验证码:"CAPTCHA",
	手机号码不能为空:"전화번호는 비워 둘 수 없습니다.",
	账号不存在:"계정이 존재하지 않습니다",
	发送成功:"성공적으로 전송",
	今日收益:"（현재 소득）",
	"您好如果您中途终止POS-STAKING系统将收取5%的STAKING费用":"안녕하세요. POS를 중도에 해지할 경우 시스템에서 5%의 POS 관리 수수료를 부과합니다. 특별 알림: 무료 노드 경험의 POS가 중단된 후에는 다시 얻을 수 없으며 수수료를 받을 수 없습니다! !",
	我同意:"나는 동의한다",
	用户账号ID:"계정 ID",
	取消:"취소하다",
	日收益:"일일 수입",
	请输入购买数量:"구매수량을 입력해주세요",
	数量不能为空:"수량은 비워둘 수 없습니다.",
	加载中:"로드 중...",
	唯一码:"로봇 코딩",
	未激活:"비활성",
	激活:"시작 전략",
	购买时间:"구매 시간",
	"钱包余额不足，激活该机器人失败":"계정 잔고 부족, 노드 POS 마이닝 불가",

	运行时长:"실행 시간",
	签约购买:"균형 구매",
	系统赠送:"시스템 선물",
	状态:"상태",
	正常:"정상",
	我的机器人:"내 퀀트 거래 로봇",
	一级会员:"레벨 1",
	二级会员:"레벨 2",
	三级会员:"레벨 3",
	人:"",
	充值客服:"충전 고객 서비스",
	充值:"재충전",
	提现:"철회하다",
	提款密码:"출금 비밀번호",
	设置交易密码:"거래 비밀번호 설정",
	登录密码:"로그인 비밀번호",
	请输入交易密码:"거래 비밀번호 입력",
	再次请输入交易密码:"거래 비밀번호 확인",
	确认: '확인하다',
	手机号码不能为空:"전화번호는 비워 둘 수 없습니다.",
	两次密码不一致:"두 비밀번호가 일치하지 않습니다",
	请输入验证码:"인증 코드를 입력하세요",
	操作成功:"성공적으로 운영",
	"用户名或密码不正确,登录失败":"사용자 이름 또는 암호가 올바르지 않아 로그인에 실패했습니다.",
	登录成功:"성공적 로그인",
	充值说明:"충전 지침",
	请先设置支付密码:"거래 비밀번호를 먼저 설정해주세요",
	复制成功:"성공적으로 복사",
	冻结机器人做单本金:"양적자금 동결",
	待审核:"검토를 보류하다...",
	成功:"성공",
	失败:"실패하다",
	审核中:"검토 중",
	在线充值:"온라인 충전",
	描叙:"설명하다",
	POS节点挖矿LV0:"POS-LV0 노드 마이닝",
	POS节点挖矿LV1:"POS-LV1 노드 마이닝",
	POS节点挖矿LV2:"POS-LV2 노드 마이닝",
	POS节点挖矿LV3:"POS-LV3 노드 마이닝",
	POS节点挖矿LV4:"POS-LV4 노드 마이닝",
	POS节点挖矿LV5:"POS-LV5 노드 마이닝",
	银行卡提现:"은행 계좌 인출",
	USDT提现:"USDT 출금",
	三级代理:"3급 에이전트",
	一级代理:"1급 에이전트",
	二级代理:"2급 에이전트",
	一级:"레벨 1",
	二级:"레벨 2",
	三级:"레벨 3",
	做单:"노드 마이닝",
	登录失败:"로그인 실패",
	请勿重复操作:"빈번한 작업을 반복하지 마십시오.",
	邀请码不正确:"초대 코드가 잘못되었습니다",
	团队返佣:"팀 커미션",
	购买机器人返佣:"로봇 커미션",
	本金返还:"POS 금액 환불",
	佣金收入:"커미션 소득",
	时间:"시각",
	机器人做单返还:"POS 금액 환불",
	涨幅:"증가",
	市场:"시장",
	客服:"섬기다",
	验证码错误:"확인코드 오류",
  付款方式:"USDT 출금 주소",
	我的留言:"내 메시지",
  暂无数据: '데이터 없음',
  银行卡:'은행 계좌',
  修改: '수정하다',
 '请确保您的钱包地址正确，如果您的地址输入错误而导致收不到钱，请及时联系在线客服协助':'지갑 주소가 정확한지 확인하십시오.주소가 잘못 입력되어 돈을 받을 수 없는 경우 온라인 고객 서비스에 즉시 연락하여 도움을 받으십시오.',
 连接借记卡:"连接借记卡",
 银行名称:'은행명',
 姓名姓氏: '이름',
 IBAN号码:'IBAN 号码',
 '* 重要提示:借记卡信息必须真实有效才能取款。':'*중요 알림: 돈을 인출하려면 은행 계좌 정보가 사실이고 유효해야 합니다.。',
 帮助中心:'도움말 센터',
 会员须知:'회원 공지',
 '24小时内使用USDT实时取款（推荐）':'1. 24小时内使用USDT实时取款（推荐）',
 '银行卡 取款 *取款时间为10:00-18:00 *发起取款后2小时内入账*申请非取款时间或跨行取款将于次日10:00-18:00到账':' 2.银行卡 取款 *取款时间为10:00-18:00 *发起取款后2小时内入账*申请非取款时间或跨行取款将于次日10:00-18:00到账',
 我知道了:'알겠어요',
 链接点击:'추천인 등록 링크',
 确定:'그래',
 建立自己的团队邀请:'나만의 팀 초대 만들기',
 此账号已冻结:"이 계정은 동결되었습니다",
 手机号存已存在:"휴대전화 번호가 이미 존재합니다.",
 注册账号已存在:"등록된 계정이 이미 존재합니다",
 请确定新密码:"새 비밀번호를 확인해주세요",
 请再次输入登录密码:"로그인 비밀번호를 다시 입력해주세요",
 密码长度不能少于6位:"비밀번호 길이는 6자 이상이어야 합니다.",
 加入:'가입하다',
 "你出售的机器人对方已接受,出售所得已打款到您账户,请查收。":
 	    "你出售的节点对方已接受,出售所得已打款到您账户,请查收。",
 	"你出售的机器人对方已拒绝接受":"你出售的节点对方已拒绝接受",
 	向你赠送一台机器人:"向你赠送一个节点，待确认",
 	向你出售一台机器人:"向你出售一个节点，待确认",
 	出售价格不能超过机器人价格:"出售价格不能超过节点价格",
 	银行卡已被绑定:"은행 계좌가 연결되었습니다",
 	USDT_TRC20提现:'USDT_TRC20 출금',
 	USDT_ERC20提现:'USDT_ERC20 출금',
 	银行卡提现:'은행 계좌 인출',
 	邀请赠送:'초대 보너스',
 	机器人返佣:'로봇 커미션',
 	升级:'성공적인 구매',
 	充值主网:'充值主网',
   "您被限制出售机器人,请联系客服了解详情":
     "您被限制出售节点,请联系客服了解详情",
   交易编号: "거래 번호",
   消息详情:"메시지 세부정보",
   个人收益累计:"누적 개인 소득",
   今日代理收益:"오늘의 에이전트 수입",
   代理累计收益:"대행누계수입",
   截图保存推荐给朋友:"추천 등록 링크를 공유하고 친구를 Plus500Ai 플랫폼에 초대하여 팀 수입 커미션 보상을 받으세요. 3급 팀원의 수입 수수료는 8%-3%-2%입니다. 팀 커미션은 하위 멤버의 일일 수입 데이터를 기준으로 매일 00:00 이후 자동으로 정산되어 계정 잔액에 입력됩니다.",
   复制:"복사",
   充值提示1:"팁 1: 최소 재충전은 10 USDT이며 재충전할 수 있습니다. 그렇지 않으면 적립되지 않습니다.",
   充值提示2:"팁 2: 충전은 체인에서 확인이 필요하며, 충전 성공 후 약 1분 안에 지갑 잔액에 도착할 것으로 예상됩니다.。",
   请输入正确的充值金额:"예치 금액 ≥ 8 USDT",
   推荐:"추천하다",
   充值金额:"입금 금액",
   请上传付款截图:"성공적인 결제의 스크린샷을 업로드하세요.",
   交易号: '트랜잭션 해시 번호',
   充值ID提示:"Txid 거래 일련번호를 붙여넣으십시오.",
   "请确保钱包地址无误，如因钱包地址填写错误导致无法收款而造成的损失，将有用户自己承担。":"지갑 주소가 정확한지 확인 부탁드리며, 지갑 주소 기입 오류로 인한 입금 실패로 인한 손실은 이용자 부담입니다.",
	上传凭证:"결제 증빙 자료 업로드",
	充值确认: "제출하다",
	不能为空:"비워둘 수 없습니다.",
  绑定钱包地址:'지갑 주소 바인딩',
	备注:"备注",
	请输入银行卡号:"은행계좌번호를 입력해주세요",
	请输入你的名字:"당신의 이름을 입력 해주세요",
	银行卡号:"은행 계좌",
	添加银行卡:"은행 계좌 연결",
	请选择银行:"은행을 선택해주세요",
	请输入钱包地址:"지갑주소를 입력해주세요",
	钱包地址:"지갑 주소",
	"密码错误次数过多,请等待x秒后再试":"잘못된 비밀번호가 너무 많습니다. 600초 동안 기다린 후 다시 시도하세요.",
	"此账号已冻结,请联系客服":"이 계정은 동결되었습니다. 고객 서비스에 문의하십시오.",
	手机号格式错误:"잘못된 전화번호",
	"取款须知":"철회 통지",
	"须知1":"1. USDT-TRC20으로 24시간 이내 실시간 출금(권장)",
	"须知2":"2. 토요일, 일요일 출금",
	"须知3":"*사용자는 토요일과 일요일에 돈을 인출할 수 있습니다.",
	"须知4":"*주말 출금은 월요일 오전 10시-오후 8시에 도착합니다.",
	我知道了:"알겠어요",
	"提现0":"1USDT=1USD ERC20",
	"提现1":"提示1：最低单笔提现100USDT/天，单笔交易手续费5USDT/次",
	"提现2":"TRC20提示2：每次提款最低10USDT，每次交易手续费1USDT。",
	"提现3":"银行账户提示3：最低单次取款为$10，交易手续费为交易金额的$6%",
	"提现4":"提现时间：提现申请提交后24小时内到账",
	
	已绑定:"주소가 묶여있다",
	去设置:"설정에 들어가",
	注册成功:"등록 성공",
	汇率:"환율",
	请输入正确的提现金额:"올바른 인출 금액을 입력하세요.",
	提款记录:"출금 내역",
	"绑定的钱包地址必须跟付款钱包一致，否则不会到账。":"tip1:绑定的钱包地址必须跟付款钱包一致，否则不会到账。",
	"充值成功后预计在一分钟左右到账，请检查钱包约。":"tip2:充值成功后预计在一分钟左右到账，请检查钱包余额。",
	请先绑定钱包地址:"먼저 지갑주소를 바인딩해주세요",
	输入邮箱:"이메일 입력",
	邮箱已存在:"사서함이 이미 존재합니다",
	注册邮箱已存在:"등록된 이메일이 이미 존재합니다",
	指定用户不存在:"지정한 사용자가 존재하지 않습니다",
	今日涨幅:"변화",
	恭喜新用户:"새로운 사용자 축하합니다 ",
	快捷充币:"보증금",
	快速买USDT:"USDT 충전",
	在线支付:"온라인 결제",
	自动到账:"자동 계정",
	线下支付:"线下支付",
	联系客服获取验证码:"확인 코드를 받으려면 고객 서비스에 문의하십시오.",
	获取:"얻다",
	退出成功:"성공적으로 종료",
	不能低于最小充值:"최소 재충전보다 낮을 수 없습니다.",
	不能低于最小提现金额:"최소 인출 금액보다 낮을 수 없습니다.",
	最小提现:"최소 인출",
	设置成功:"성공적으로 설정",
	官方充币:"시스템 보증금",
	银行卡充值:"은행 충전",
	等待审核:"검토됨",
	可提现金额不足:"출금 가능한 금액이 부족합니다.",
	未经授权:"무단",
	交易密码不正确:"거래 비밀번호가 올바르지 않습니다",
	提现次数不足:"불충분한 인출 시간",
	官网:"官网",
	账户usdt不足:"계정 USDT 잔액이 부족합니다.",
	激活成功: "POS 성공",
	操作成功: "성공적인 작업",
	修改成功:"성공적으로 수정됨",
	注册说明:"모바일 OTP와 이메일 CAPTCHA 인증 방법 중 하나만 선택하시면 성공적으로 등록하실 수 있습니다",
	弹窗内容:
	"Plus500Ai 양적 거래 플랫폼에 오신 것을 환영합니다.각 사용자는 등록 보너스로 1 USDT를 사용하여 NO.0 양적 로봇 거래 전략 패키지를 구매할 수 있습니다. 이 패키지는 1일 동안 유효하고 0.5 USDT를 무료로 얻습니다. 다른 정량적 거래 전략 패키지에는 해당 수수료를 사용해야 합니다. 사용자가 로봇을 구매한 후 자동으로 실행되기 전에 정량적 거래 전략을 활성화해야 합니다.각 거래 전략은 서로 다른 유효 기간에 해당합니다. 사용자가 해당 로봇 포인트를 구매한 후 전략을 활성화하기 위해 클릭하지 않으면 거래 수익을 얻을 수 없습니다.",
	标题1:'1.Plus500 플랫폼 소개',
	标题2:'2. AI 퀀트 트레이딩의 장점',
	标题3:'3. Plus500Ai를 선택하는 이유는 무엇입니까?',
	标题4:'4. Plus500Ai 가입방법',
	标题5:'5.Plus500Ai 시장 전망',
	标题6:'6.Plus500Ai 수익모델',
	标题7:'7.입금 및 출금규정',
	标题8:"배틀 로얄 게임플레이",
	内容8:"Plu500Ai 플랫폼의 배틀 로얄 게임에는 8개의 방이 있으며 플레이어는 8개의 방 중 원하는 방을 선택하여 금화를 넣을 수 있습니다. 시스템 카운트다운이 끝나면 게임 시스템에 킬러가 나타납니다. 킬러는 무작위로 방에 들어갑니다. 다른 플레이어들이 이 방에 있는 금화를 공유하여 이익을 얻을 수 있습니다. 배틀 로얄 게임의 게임 플레이는 상대적으로 짧으며, 게임 시간은 단 몇 분에 불과하므로 플레이어는 자유 시간에 플레이할 수 있습니다. 분할량 = 해당 기간 동안 킬러가 죽인 방의 총 개수. 실제 지분금액 = 지분금액 * 0.9 (소각비율 0.1 제외) 공유 비율 = 실제 공유 금액 / 다른 방의 총 배팅 금액. 당첨금액 = 배분율 * 실제 배팅금액입니다. 금화 1개 = 1 USDT이며, 배틀 로얄 게임에 참여하려면 최소 10 USDT가 필요합니다.",
	内容1:"Plus500 거래 플랫폼은 Plus500SEY Ltd.에서 제공됩니다. 따라서 Plus500SEY Ltd는 이 웹사이트에 설명되거나 제공되는 금융 상품의 발행자이자 판매자입니다. Plus500SEY Ltd는 빅토리아에 사무실을 두고 있는 세이셸 기반 회사(회사 번호 8426415-1)입니다. 이 회사는 라이센스 번호 SD039로 세이셸 금융 서비스 당국(FSA)의 승인 및 감독을 받고 있으며 다양한 기본 상품에 대한 차액 계약(CFD)을 제공합니다. 이 회사는 현재 2,800개 이상의 금융상품 포트폴리오를 제공하는 빠르게 성장하는 CFD 제공업체입니다. Plus500SEY Ltd는 런던 증권 거래소의 주요 시장에 상장되어 있으며 하이파에 본사를 두고 있는 Plus500 Ltd의 자회사입니다.",
	内容2:'AI 완전 자동화된 양적 거래의 장점은 합리적인 도구를 사용하여 모델 설정에 따라 거래를 운영한다는 것입니다. 로봇은 감정 변화가 없으며 운영 과정에서 인간의 본성, 인간의 탐욕과 두려움 및 기타 많은 단점과 약점을 극복할 수 있습니다. 수작업은 시장의 상승과 하락 및 기타 여러 요인에 의해 영향을 받는 경우가 많으며, 오랫동안 최고의 수익 모델에 따라 운영할 수 없습니다.',
	内容3:'Plus500Ai는 핵심 제품을 신규 및 기존 시장으로 확장하고, 새로운 거래 및 금융 상품을 출시하며, 고객과의 참여를 심화함으로써 다중 자산 핀테크 그룹으로서의 입지를 점차 강화했습니다. 업계 최고의 보안 기술로 자산을 보호하고 암호화 키를 보호하세요. Plus500Ai는 또한 글로벌 보호권을 제공합니다. 즉, 규제 및 감사를 받고 최대 2억 5천만 달러까지 보장됩니다. 자산을 보유하는 것뿐만 아니라 이를 활용하는 것을 목표로 자본을 배치하십시오. Plus500Ai를 사용하면 위험 허용 범위에 따라 Ai 양적 거래부터 기존 기본 서비스까지 다양한 방식으로 자금을 배치할 수 있습니다. 추가 블록체인 엔지니어를 고용하지 않고도 사용자에게 더 많은 서비스를 제공할 수 있는 온라인 플랫폼입니다.',
	内容4:"Plus500Ai 설립 이후 공식 웹사이트는 WWW.PLUS500AI.COM 단 하나뿐입니다. 다른 웹사이트는 당사 웹사이트가 아니기 때문에 모든 사용자는 공식 웹사이트에 로그인하여 앱을 다운로드해야 합니다. 회원가입을 위해서는 이용자의 휴대폰번호, 이메일주소, 추천인 초대코드를 입력하셔야 빠르게 회원가입이 가능합니다. 고객과의 모든 의사소통은 이메일이나 온라인 채팅을 통해 서면으로 이루어집니다. 이메일을 통해 Plus500에 문의하려면 문의하기 페이지에서 양식(신청 양식)을 작성하세요. 신청서를 제출한 후 Plus500은 귀하가 신청서에 지정한 이메일 주소로 이메일을 통해 직접 답변을 보내드립니다. 질문이 있으시면 고객 서비스에 문의하십시오.",
	内容5:'Plus500Ai는 제품, 장치 및 국가 전반에 걸쳐 고객에게 안정적이고 직관적인 금융 거래 기회를 제공합니다.우리는 항상 제품과 팀의 우수성을 추구해 왔습니다. 고급 독점 기술과 고객 중심 접근 방식을 통해 사용자는 최고 수준의 거래 서비스 경험을 누릴 수 있습니다.',
	内容6:"Plus500Ai는 수석 데이터 분석가 팀에 의해 암호화폐 시장에 대한 전문적인 양적 거래 전략을 제공합니다.Ai 양적 거래 로봇에 투자하는 사용자는 로봇과 일치하는 거래 전략에 의존하여 양적 거래 수익을 얻을 수 있습니다. Ai 퀀트 트레이딩 로봇은 Plus500Ai의 시장 매매 전략을 기반으로 최적의 매매 시점을 주기적으로 분석하고, 저수준 고가 매매 모델을 통해 퀀트 매매 수익을 얻습니다. Plus500Ai는 투자 사용자에게 매일 최적의 양적 거래 전략을 제공하며, 플랫폼은 사용자의 양적 소득 수익의 5%를 플랫폼 운영 자금으로 추출합니다.",
	内容7:'Plus500Ai는 현재 USDT 입출금을 지원하고 있으며, 향후 다른 채널이 개설되면 공식 홈페이지에 공지할 예정입니다. 최소 입출금은 모두 10 USDT이며, 가장 빠른 입출금은 5분 이내, 가장 느린 입출금은 24시간 이내입니다. 사용자 출금 시간에는 제한이 없으며, 언제든지 출금이 가능합니다. 출금은 영업일 기준 5분~24시간 이내에 도착합니다. 주말 출금은 월요일에 처리될 예정입니다.',
	BSC提现:"USDT_BSC 출금",
	社区Ads收益:"커뮤니티 광고 수입",
	Ads收益区间:"광고 수입 1-1000$",
	申请Ads费用:"애플리케이션 광고 수수료",
	Ads促销地址:"광고 프로모션 주소",
	填写Ads促销地址:"광고 프로모션 주소 입력",
	申请Ads费用须知:"광고 수수료 신청 안내",
	须知A:"1. 사용자는 프로모션 비디오를 녹화하고 광고 프로모션을 위해 자신의 YouTube, Facebook, 채널 또는 기타 커뮤니티에 업로드하고 $1-$1000 보너스를 신청하며 플랫폼은 신청서를 제출한 후 3일 후에 광고 비디오 자료를 검토하고 광고를 게재합니다. 동영상 프로모션 효과에 따라 계정에 보너스 계정 잔액, 홍보 효과가 없는 동영상은 검토할 수 없습니다.",
	须知B:"2. 같은 채널의 같은 광고 홍보 영상은 보너스 신청이 1회만 가능하며, 새로운 홍보 영상 자료는 일주일에 한 번 공개될 수 있으니 악의적인 반복 요청을 보내지 마세요. 플랫폼에서 자신이 하지 않은 광고 요청을 여러 번 악의적으로 제출하거나 검토되지 않은 광고 자료를 제출하면 시스템에서 감지한 후 계정이 차단됩니다.",
	须知C:"3. 사용자가 녹화한 광고 동영상 자료는 기본적인 플랫폼 소개와 플랫폼 기능 설명이 있어야 하며, 동영상 시간은 5분 이상이어야 합니다. 채널 구독자가 많을수록 동영상의 홍보 효과가 높아지고, 신청할 수 있는 광고 수수료. 구체적인 광고 수수료는 YOUTUBE를 예로 들면 다음과 같습니다.",
	须知D: "가입자 0-2K: 신청 금액 1-20$",
	须知D1:"가입자 3K-10K: 신청 금액 21-40$",
	须知D2:"가입자 11K-30K: 신청 금액 41-100$",
	须知D3:"가입자 31K-50K: 신청 금액 101-300$",
	须知D4:"가입자 51K-100K : 신청금액 301-1000$",
	须知D5:"4. 시스템은 매주 일요일 광고 보너스 신청을 검토하고 처리하며 자격을 갖춘 사용자는 시스템에서 보낸 보너스를 받게 됩니다.",
	请求审核:"검토를 요청하다",
	申请金额:"신청 금액",
	URL促销地址:"URL 프로모션 주소",
	提交时间:"제출 시간",
	需要更新的银行卡不存在:"업데이트할 은행 카드가 없습니다.",
	银行卡提现:"은행 카드 현금 인출",
	预计收入:"추정 수입",
	总投资:"총 투자",
	购买价:"구매 가격",
	邀请:"초대하다",
	成员:"회원",
	积分等级折扣规则介绍3:"이 할인 금액은 2023년 12월 31일까지만 유효하며, 2024년 1월 1일 이후에는 새로운 조정이 적용됩니다.",
	LV1佣金:"레벨1 커미션",
	LV2佣金:"레벨2 커미션",
	LV3佣金:"레벨3 커미션",
	合格条件:"1인당 최소 8$ 입금",
	随时可退:"언제든지 환불 가능",
	登录密码错误:"잘못된 로그인 비밀번호",
	充值奖金:"USDT≥50USDT 입금, 보너스 3%",
	充值奖金1:"IDR≥50USDT 입금, 보너스 2%",
	充值奖金2:"THB≥50USDT 입금, 보너스 5%",
	活动已过期:"이벤트가 종료되어 다음 이벤트를 기다리고 있습니다",
	不能重复发送短信:"10분 이내에 문자 메시지를 반복해서 보낼 수 없습니다. 직접 등록하려면 EMAIL CAPTCHA를 사용하는 것이 좋습니다.",
	限购说明1:"안녕하세요. NO.0 퀀트 로봇 트레이딩 전략 패키지 사용자는 1인당 1회만 구매하실 수 있습니다..",
	修改钱包地址提示:"USDT 출금 주소는 바인딩 후에는 수정할 수 없으며, 도움이 필요한 경우 고객센터로 문의해주세요.",
	修改银行提示:"출금계좌는 바인딩된 후에는 수정할 수 없으며, 도움이 필요한 경우 고객센터로 문의하시기 바랍니다.",
	注册推送消息1:"INGSAI에 오신 것을 환영합니다. 신규 사용자가 POS 채굴 노드를 시작한 후 5분마다 POS 채굴 수익을 받게 됩니다. 팀 커미션 수입은 하루에 약 00.00에 자동으로 계정 잔액에 입력됩니다.",
	限购说明:"안녕하세요, 해당 퀀트 로봇 패키지의 최대 구매 횟수는 10회이며, 다른 유형의 퀀트 트레이딩 패키지 구매를 권장합니다.",
	注册协议:"(계정 등록)을 클릭하면 귀하가 18세 이상임을 확인하게 됩니다. 귀하는 Plus500AI가 제품/서비스에 대한 관련 정보를 제공하기 위해 귀하의 이메일 주소나 기타 개인 데이터를 사용하여 전자적으로 귀하에게 연락할 수 있다는 것을 알고 있습니다. 또한 귀하가 거래 경험을 최대한 활용할 수 있도록 시장 이벤트에 대해 알려드릴 수도 있습니다. 플랫폼의 (알림 설정) 및 (개인 정보 설정) 탭에서 알림 기본 설정을 관리하고 수정할 수 있습니다. 귀하는 언제든지 이러한 커뮤니케이션을 구독 취소할 수 있습니다. 자세한 내용은 당사의 (개인정보 보호 계약)을 검토하세요.",
	公司说明1:"Plus500AI는 Plus500 Ltd.의 등록 상표입니다. Plus500 Ltd는 다음 자회사를 통해 운영됩니다.",
	公司说明2:"Plus500SEY Ltd는 세이셸 금융 서비스 당국(라이센스 번호 SD039)의 승인 및 규제를 받습니다.",
	公司说明3:"Plus500SEY Ltd는 이 웹사이트에 설명되어 있거나 제공되는 금융 상품의 발행자이자 판매자입니다.",
	公司说明4:"Plus500UK Ltd는 금융행위감독청(Financial Conduct Authority)의 승인 및 규제를 받습니다. 금융 행위 당국 등록 번호: 509909. 소매 사용자는 암호화폐 CFD를 사용할 수 없습니다.",
	公司说明5:"Plus500CY Ltd는 키프로스 증권거래위원회(인증 코드: 250/14)의 승인 및 규제를 받습니다. 영국 개인 투자자는 암호화폐 CFD를 이용할 수 없습니다.",
	公司说明6:"Plus500AU Pty Ltd는 ASIC에서 발행한 AFSL(No. 417727), 뉴질랜드 FMA에서 발행한 FSP(No. 486026) 및 남아프리카 FSCA에서 발행한 공인 금융 서비스 제공업체(No. 47546) 라이센스를 보유하고 있습니다.",
	公司说明7:"Plus500EE AS는 에스토니아 금융감독청(라이센스 번호 4.1-1/18)의 승인 및 규제를 받습니다.",
	公司说明8:"Plus500SG Pte Ltd(UEN 201422211Z)는 싱가포르 통화청에서 발행한 자본 시장 서비스 라이선스를 보유하고 있어 자본 시장 상품을 거래할 수 있습니다(라이선스 번호 CMS100648).",
	公司说明9:"Plus500AE Ltd는 두바이 금융 서비스 당국(F005651)의 승인 및 규제를 받습니다.",
	加入我们的社群:"우리 커뮤니티에 가입하세요",
	PLUS500集团:"PLUS500 그룹",
	受监管的操作:"규제된 운영",
	健全的全球监管框架:"강력한 글로벌 규제 프레임워크",
	治理和社会责任:"거버넌스와 사회적 책임",
	底部说明:"Plus500은 비즈니스의 모든 측면에서 책임감 있고 지속 가능한 방식으로 운영하기 위해 최선을 다하고 있으며 이러한 접근 방식이 회사의 의무이자 효과적인 관리의 필수적인 부분이라고 믿습니다. Plus500은 직원, 고객, 지역 사회, 자선 단체 및 주주를 위한 실질적인 가치를 창출하기 위해 다양한 ESG 이니셔티브에 전념하고 있습니다.",
	投资者联系方式:"투자자 연락처",
	投资者关系主管:"투자자 관계 책임자",
	AI智慧型量化交易:"AI 지능형 양적 거래",
	订单执行快速可靠:"주문 실행이 빠르고 안정적입니다.",
	全天候自动分析行情:"24시간 자동 시장 분석",
	交易受监管低风险:"거래가 규제되고 위험이 낮습니다.",
	稳定策略高效收益:"안정적인 전략과 효율적인 수익",
	取款快速且安全:"빠르고 안전하게 돈을 인출하세요",
	游戏说明:"게임에 참여하고 돈을 벌기 위해 필요한 최소 잔액은 10 USDT입니다.",
}
