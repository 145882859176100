<template>
  <div class="radioButton">
    <van-button class="btn" round block type="info" @click="clickEvent">
      <slot></slot>
    </van-button>
  </div>
</template>

<script>
export default {
  methods: {
    clickEvent() {
      this.$emit('clickEvent')
    }
  }
}
</script>

<style lang="scss" scoped>
.radioButton {
  width: 350px;
  margin: 0 auto;
  margin-bottom: 40px;
  .btn {
    box-shadow: 0px 10px 20px #0D6EFD;
    font-size: 30px;
	font-weight:bold;
    height: 103px;
    background: linear-gradient(to right, #3a85f2, #0D6EFD);
    border: none;
	border-radius: 10px;
    color: #fff;
  }
}
</style>
