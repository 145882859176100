<template>
  <div>
    <layout :isShowFooter="false">
      <template #title><img class="team-icon" style="width:111px;" src="@/assets/img/about.png" alt="" /></template>
      <template #content>
        <div class="content">
          <div
            class="ml32 mr32 robot hide box mt20"
            v-for="item in aituring"
            :key="item"
          >
            <div class="flex_row_space-between mt30">
              <div class="flexbox hcenter ml32">
                <img class="robotIcon" src="@/assets/img/ai.gif" alt="" />
                <div class="ml10" style="color: #fff;font-weight:bold;">{{ item.name }} | {{ $t('机器人性能') }}</div>
              </div>
              <!--<div class="mr32" style="color: #0d9135">{{ item.name }}</div>-->
            </div>

            <div class="flex_row_space-between mt42">
              <div class="flexbox hcenter ml32" >
                <span class="f30 ml32" style="color:#fff;font-size: 20px;font-weight:bold;">{{ item.price }} USDT</span>
                <!--<span class="ccolor"></span
                ><span class="f30">{{
                  (item.price * info.rate).toFixed(0)
                }}</span>-->
              </div>
              <div class="mr32">
                <!--<span class="f30">{{ item.dan_price }} USDT</span>-->
                <!--<span class="ccolor"></span
                ><span class="f30 mr32">{{
                  (item.dan_price * info.rate).toFixed(0)
                }}</span>-->
              </div>
            </div>

            <div class="flex_row_space-between mt30 ccolor">
              <div class="flexbox hcenter ml32">
                <span class="ml32" style="color:#fff;">{{ $t('机器人价格') }}</span>
              </div>
              <!--<div class="mr32">
                <span class="mr32">{{ $t('激活金额') }}</span>
              </div>-->
            </div>

            <div class="flex_row_space-between mr32 ml32 mt42 input">
              <div class="flexbox hcenter">
                <span class="ml32 ccolor" style="color:#fff;font-weight:bold;">{{ $t('有效期') }}:</span>
                <span class="ml10" style="color:#fff;font-size:22px; font-weight:bold;">{{ item.gq_day }}</span>
                <span class="ml10 ccolor" style="color:#fff;font-weight:bold;">{{ $t('天') }}</span>
				
              </div>
              <div class="mr32">
                <div
                  class="mr32 btn bcolor flexbox center"
                  @click="qianyuebuy(item)"
                >
                  {{ $t('去购买') }}
                  <!--<img
                    class="jiantou-b ml10"
                    src="@/assets/icon/jiantou-b.png"
                    alt=""
                  />-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </layout>
    <popup ref="popup">
      <div class="popuptip" style="color: #fff">
        <div class="flex_row_space-between f32">
          <div class="ml32">{{ $t('买个机器人') }}</div>
          <div class="mr32 flexbox" @click="$refs.popup.hide()">X</div>
        </div>

        <div class="flex_row_space-between f28 mt50">
          <div class="ml32">{{ $t('我的钱包余额') }} :</div>
          <div class="mr32 flexbox hcenter">
            <span class="f32">{{ info.usdt }}</span>
            <span class="ccolor f18">&nbsp; USDT</span>
          </div>
        </div>

        <div class="flex_row_space-between byrobot f28 mt50 ml32 mr32">
          <div class="ml32 flexbox hcenter bcolor">
            <img class="robotIcon" src="@/assets/img/ai.gif" alt="" />
            <span class="ml20">{{ $t('机器人') }}</span>
          </div>
          <div class="flexbox hcenter mr32 bcolor">
            <span class="f32">{{ ainame }}</span>
            <span class="f18"></span>
          </div>
        </div>
        <div class="ml32 mt50">
          <span class="f32">{{ $t('机器人价格') }} : {{ price }}</span>
          <span> USDT</span>
          <span class="ccolor ml10 mr10"><span style="font-size: 12px;"></span></span>
          <!--<span class="f32">{{ (price * info.rate).toFixed(0) }}</span>-->
        </div>
        <div class="ml32 mt20">
          <span>{{ $t('预计日收益') }} ：</span>
          <span>{{ earnings }} USDT</span>
        </div>
        <!--<div class="ml32 mt20">
          <span>{{ $t('激活金额') }} ：</span>
          <span>{{ dan_price }} USDT</span>
        </div>-->
        <div class="ml32 mt20">
          <span>{{ $t('有效期') }} ：</span>
          <span>{{ gq_day }} {{ $t('天') }}</span>
        </div>
        <div class="ml32 mr32 buy mt30 flex_row_space-between">
          <input
            class="flex1 f32 ml20"
            type="text"
            style="color: #ffffff"
            :placeholder="$t('请输入购买数量')"
            v-model="value"
            maxlength="1"
            @input="changeInput()"
			disabled
          />
          <span class="ccolor mr20">{{ $t('个机器人') }}</span>
        </div>
        <div class=" "></div>
        <div class="ml32 ccolor" style="margin-top: 15px;">
          <span v-html="aitext"></span>
         
        </div>
        <div class="mt50" @click="payai">
          <hbbtn class="">{{ $t('购买') }}（ {{ price2 }} USDT ）</hbbtn>
        </div>
      </div>
    </popup>
  </div>
</template>

<script>
import popup from '@/components/popup'
import hbbtn from '@/components/hb-btn'
import { Toast } from 'vant'
import {
  apiHangqing,
  apiRobots,
  apiUser_mas2,
  apibuyrobots,
  apimyrobots,
  apiwenzhang,
  getMoble,
  apimyshouyi,
  apizsrobots,
  apifeerobot
} from '@/http/api/'
export default {
  components: {
    popup,
    hbbtn
  },
  data() {
    return {
		 flag: false,
      aituring: [],
      token: '',
      info: [],
      value: 1,
      price2: '',
      price: '',
      ids: '',
      gq_day: '',
      earnings: '',
      ainame: '',
      dan_price: '',
      istest: false,
	  show:false,
	  type:0,
	  info2:[],
	  aitext:""
    }
  },
  mounted(){
	  // 快捷购买
	  if(this.type == 1){
		  this.qianyuebuy(this.info2);
		  this.istest = false;
	  }
	  // 如果type 100  就是0号机器人信息
	  if(this.type == 100){
		  this.istest = true;
		  this.qianyuebuy(this.info2);
	  }
  },
  created() {
    var token = localStorage.getItem('key')
    if (!token) {
      this.$router.replace('/login')
    }
    this.token = token
    Toast.loading({
      duration: 0,
      message: this.$t('加载中'),
      forbidClick: true
    })
		
    apiRobots({}).then((res) => {
      Toast.clear()
      this.aituring = res.info
    })
    apiUser_mas2({
      userid: token
    }).then((res) => {
      // 未登录踢下线
      if (res.status == 10) {
        this.$router.replace('/login')
      }
      this.info = res.user
    })
	
	this.type = this.$route.query.type;
	this.info2 = this.$route.query.info
	
	var that = this
	// apiwenzhang({}).then((res) => {
		//   for (let i = 0; i < res.length; i++) {
		//     if (res[i].title == '机器人说明') {
		//       that.aitext = res[i].content;
		//     }
		//   }
		// })
		 that.aitext = this.$t('机器人说明')
  },
  methods: {
    changeInput() {
      this.price2 = this.value * this.price
    },
    // 签约购买
    qianyuebuy(info) {
      this.$refs.popup.show()
		
      this.price = info.price
      this.earnings = info.earnings
      this.dan_price = info.dan_price
      this.gq_day = info.gq_day
      this.price2 = info.price
      this.ids = info.id
      this.ainame = info.name
    },
    payai() {
		
		
		 if (this.flag) return;
		            this.flag = true;
      if (this.value == '') {
        Toast.fail({
          message: this.$t('数量不能为空'),
          className: 'toastIndex'
        })
		
		this.flag = false;
        return
      }
      if (this.istest) {
        Toast.loading({
          duration: 0,
          message: this.$t('加载中'),
          forbidClick: true
        })
        apifeerobot({
          userid: this.token
        }).then((res) => {
          Toast.clear()
		  	this.flag = false;
          if (res.status == 0) {
			  
			  
			  Toast.fail({
			    message: this.$t(res.info),
			    className: 'toastIndex'
			  })
           
          } else {
			   
			   Toast.success({
				   message: this.$t(res.message),
				   className: 'toastIndex'
			   })
			   setTimeout(() => {
					this.$router.push('/myRobot')
					this.show = false
			   }, 1000)
           
            
          }
        })
      } else {
        Toast.loading({
          duration: 0,
          message: this.$t('加载中'),
          forbidClick: true
        })
        apibuyrobots({
          userid: this.token,
          kjid: this.ids,
          num: this.value
        }).then((res) => {
			
				this.flag = false;
          Toast.clear()
          if (res.status == 0) {
            Toast.fail({
              message: this.$t(res.info),
              className: 'toastIndex'
            })
          } else {
            Toast.success({
			   message: this.$t(res.message),
			   className: 'toastIndex'
            })
			setTimeout(() => {
				this.$router.push('/myRobot')
				this.show = false
			}, 1000)
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  .robot {
    height: 450px;
	
    background: url('../../assets/aitrade.jpg') no-repeat center center;
    background-size: 100% 100%;
    border-radius: 10px;
    .input {
      height: 89px;
      background: hsla(0, 0%, 100%, .1);
      border-radius: 10px;
    }
	
	.robotIcon {
	width: 100px;
	height: 120px;	
	 }	
    .btn {
      width: 100px;
      height: 56px;
      background: hsla(0, 0%, 100%, .3);
      box-shadow: 0px 8px 30px 1px rgba(243, 204, 46, 0.30000001192092896);
      border-radius: 10px;
    }
  }
  .popuptip {
    padding: 40px 0;
    
    background: #07183d;
    border-radius: 10px;
    .byrobot {
      height: 130px;
      background: linear-gradient(to right, #3a85f2, #0D6EFD);
      border-radius: 10px;
    }
    .buy {
      height: 107px;
      background: hsla(0, 0%, 100%, .1);
      border-radius: 10px;
      opacity: 1;
      border: 1px solid rgba(243, 204, 46, 0.20000000298023224);
    }
  }
}
</style>
