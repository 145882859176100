const state = {
  partnerList: [
    {
      icon: require('@/assets/icon/chicago1.png'),
	  
    },
	{
	  icon: require('@/assets/icon/chicago2.png'),
	  
	},
    {
      icon: require('@/assets/icon/london1.png'),
      
    },
    {
      icon: require('@/assets/icon/sydney1.png'),
      
    },
	{
	  icon: require('@/assets/icon/sydney2.png'),
	  
	},
	{
	  icon: require('@/assets/icon/sydney3.png'),
	  
	},
    
    {
      icon: require('@/assets/icon/telaviv1.png'),
      
    },
    
    {
      icon: require('@/assets/icon/limassol1.png'),
      
    },
    
	{
	  icon: require('@/assets/icon/dubai1.png'),
	  
	}
	
  ]
}

export default {
  state
}
